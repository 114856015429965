import React, { useContext } from "react"
import tab from "../utility/ZoneNavTabEnum"
import { ZonePageContext } from "../states/ZonesPageContext"
import EconomicValue from "../views/EconomicValues"
import ImpactOverview from "../views/ImpactOverview"
import Beneficiation from "../views/ValueAddition"
import Diversification from "../views/Diversification"
import FiscalImpact from "../views/FiscalImpact"
import Sustainability from "../views/Sustainability"
import JobsPage from "../views/JobsPage"

const ZoneNavTabEnum = ({ zones }) => {
  const { tabs } = useContext(ZonePageContext)
  const [view] = tabs
  switch (view) {
    case tab.Overview:
      return <ImpactOverview zones={zones} />
    case tab.Economic:
      return <EconomicValue zones={zones} />
    case tab.Beneficiation:
      return <Beneficiation zones={zones} />
    case tab.Diversification:
      return <Diversification zones={zones} />
    case tab.Fiscal:
      return <FiscalImpact zones={zones} />
    case tab.Sustainability:
      return <Sustainability zones={zones} />
    case tab.JobsPage:
      return <JobsPage zones={zones} />
    default:
      return <ImpactOverview zones={zones} />
  }
}
export default ZoneNavTabEnum
