import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import OverviewCard from "../elements/OverviewCards"
import LifeCyle from "../model/LifeCycle"
import store from "../Store"
import GlobeLoading from "../elements/GlobeLoading"
import Typography from "@mui/material/Typography"
import { filterQuery } from "../model/Query"
import Barchart from "../components/Barchart"
import StackedBarchart, {
  stackPercentageOptions,
} from "../components/StackedBarchart"
import Button from "@mui/material/Button"
import GaugeChart from "../components/GaugeChart"
import Paper from "@mui/material/Paper"
import DialogPopup from "../components/DialogPopup"

const buttonProps = {
  variant: "contained",
  sx: {
    fontFamily: '"Open Sans"',
    color: "white",
    backgroundColor: "#CED4DA",
    textTransform: "None",
    border: "5px solid #E9ECEF",
    borderRadius: "77px",
    height: "100%",
    width: "100%",
  },
}

const Sustainability = ({ zones }) => {
  const [currentTable, setCurrentTable] = useState("Supply Chain Management")
  const [currentTab, setCurrentTab] = useState("1")
  const buttonsValues = ["Total ", "Direct", "Indirect"]
  const [active, setActive] = useState(buttonsValues[0])
  const [sector, setSector] = useState("All sectors")
  const [indicator, setIndicator] = useState("Proportion of wood used")
  const [years, setYears] = useState(["2018", "2019", "2020", "2021", "2022"])
  const [yearsList, setYearsList] = useState([])
  const [sectorList, setSectorList] = useState([])
  const [indicatorList, setIndicatorList] = useState([])

  const table = `sustainability_${currentTable
    .toLowerCase()
    .replaceAll(" ", "_")}`
  const sustainabilityData = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === table
  )[0]

  const zoneInfo = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.zone === zones
  )

  const barGraphIndicators = {
    gabon: {
      sustainability_supply_chain_management:
        "Value per 1,000 m3 of wood supplied",
      sustainability_circularity: "Proportion of waste offset",
      sustainability_skills_development:
        "Students enrolled in vocational training centres",
      sustainability_diversity_and_inclusion: "Total wages by gender",
    },
    benin: {
      sustainability_supply_chain_management:
        "Value per metric tonne of cotton supplied - historic",
      sustainability_circularity: "Proportion of water recycled",
      sustainability_skills_development:
        "Students enrolled in vocational training centres",
      sustainability_diversity_and_inclusion: "Total wages by gender",
    },
    togo: {
      sustainability_supply_chain_management:
        "Value per metric tonne of cotton supplied - historic",
      sustainability_circularity: "Proportion of water recycled",
      sustainability_skills_development:
        "Students enrolled in vocational training centres",
      sustainability_diversity_and_inclusion: "Total wages by gender",
    },
  }

  const Yearsvariables = {
    variables: { table: table, filter: "year", schema: zones },
  }
  const Sectorvariables = {
    variables: { table: table, filter: "Sector", schema: zones },
  }
  const Indicatorvariables = {
    variables: { table: table, filter: "Indicator", schema: zones },
  }
  const tabs = [
    {
      id: 1,
      tabTitle: "Overview",
      content: overviewData(),
    },
    {
      id: 2,
      tabTitle: "Explore Data",
      content: exploreData(),
    },
  ]
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  function handleToggleChange(event) {
    setActive(event.target.value)
  }

  function Center({ children }) {
    return (
      <div
        style={{
          display: "flex-col",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    )
  }

  function exploreData() {
    const DiversityStackChart = () => {
      return (
        <LifeCyle
          query={store.queries.dataQuery}
          variables={{ table: sustainabilityData.table, schema: zones }}
          processor={sustainabilityData.preProcessor2}
          loader={<GlobeLoading />}
        >
          {(data) => {
            return (
              data && (
                <>
                  <Typography variant="h6" sx={{ color: "#2F2F7D" }}>
                    {barGraphIndicators[zones][table]}
                  </Typography>
                  <StackedBarchart
                    data_={data.data}
                    labels={data.labels}
                    otherOptions={stackPercentageOptions}
                  />
                </>
              )
            )
          }}
        </LifeCyle>
      )
    }

    const BarGraph = () => {
      return (
        <LifeCyle
          query={store.queries.exporeSustainability}
          variables={{
            table: sustainabilityData.table,
            indicator: barGraphIndicators[zones][table],
            years: ["2019"],
            tab: sustainabilityData.tabs,
            schema: zones,
          }}
          processor={sustainabilityData.preProcessor2}
          loader={<GlobeLoading />}
        >
          {(data) =>
            data.map((indicator) => (
              <>
                <Typography variant="h6" sx={{ color: "#2F2F7D" }}>
                  {barGraphIndicators[zones][table]}
                </Typography>
                <Barchart data_={indicator.data} labels={indicator.label} />
              </>
            ))
          }
        </LifeCyle>
      )
    }
    return (
      <div>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Button
              {...buttonProps}
              id="Supply Chain Management"
              onClick={(e) => setCurrentTable(e.target.id)}
              style={{
                backgroundColor:
                  currentTable === "Supply Chain Management"
                    ? "#9ACA3C"
                    : "#CED4DA",
              }}
            >
              Supply Chain Management
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Button
              {...buttonProps}
              id="Circularity"
              onClick={(e) => setCurrentTable(e.target.id)}
              style={{
                backgroundColor:
                  currentTable === "Circularity" ? "#9ACA3C" : "#CED4DA",
              }}
            >
              Circularity
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Button
              {...buttonProps}
              id="Skills Development"
              onClick={(e) => setCurrentTable(e.target.id)}
              style={{
                backgroundColor:
                  currentTable === "Skills Development" ? "#9ACA3C" : "#CED4DA",
              }}
            >
              Skills Development
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Button
              {...buttonProps}
              id="Diversity and Inclusion"
              onClick={(e) => setCurrentTable(e.target.id)}
              style={{
                backgroundColor:
                  currentTable === "Diversity and Inclusion"
                    ? "#9ACA3C"
                    : "#CED4DA",
              }}
            >
              Diversity & Inclusion
            </Button>
          </Grid>
        </Grid>
        <div className="pt-4 h-1/2">
          <Grid
            container
            spacing={2}
            direction="row"
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item xs={3}>
              {indicatorList.length > 0 &&
                indicatorList.map((item) => {
                  return (
                    <LifeCyle
                      query={store.queries.exporeSustainability}
                      variables={{
                        table: sustainabilityData.table,
                        indicator: item,
                        years: ["2019"],
                        tab: sustainabilityData.tabs,
                        schema: zones,
                      }}
                      processor={sustainabilityData.preProcessor3}
                      loader={<GlobeLoading />}
                    >
                      {(data) =>
                        data.map(
                          (indicator) =>
                            !isNaN(indicator.sum) && (
                              <>
                                <Grid
                                  item
                                  sx={{ borderTop: "5px solid #9ACA3C", mb: 2 }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: "#2F2F7D",
                                      fontWeight: "bold",
                                      textAlign: "center",
                                    }}
                                  >
                                    {item}
                                  </Typography>
                                  <GaugeChart
                                    data_={[
                                      indicator.sum,
                                      indicator.sum >= 100
                                        ? 0
                                        : 100 - indicator.sum,
                                    ]}
                                    labels={["score", "difference"]}
                                  />
                                </Grid>
                              </>
                            )
                        )
                      }
                    </LifeCyle>
                  )
                })}
            </Grid>
            <Grid item xs={indicatorList.length > 0 ? 9 : 12} sx={{ mb: 2 }}>
              <Paper
                elevation={0}
                square
                sx={{ pl: 1 }}
                style={{
                  backgroundColor: "#E9ECEF",
                  height: "100%",
                }}
              >
                {table === "sustainability_diversity_and_inclusion" ? (
                  <DiversityStackChart />
                ) : (
                  <BarGraph />
                )}
              </Paper>
            </Grid>
          </Grid>
        </div>

        <DialogPopup zones={zones}/>
      </div>
    )
  }
  useEffect(() => {
    const getDropdownLists = async () => {
      const years_ = await filterQuery(Yearsvariables)
      const sectors_ = await filterQuery(Sectorvariables)
      const indicators_ = await filterQuery(Indicatorvariables)
      setYearsList(years_)
      setSectorList(sectors_)
      setIndicatorList(
        [
          ...indicators_.filter(
            (item) => !item.includes(barGraphIndicators[zones][table])
          ),
        ].filter((item) => item)
      )
    }
    getDropdownLists()
  }, [table])

  function overviewData() {
    return (
      <div>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid xs={6} md={8}>
            <div className="pl-10">
              <Typography
                sx={{
                  fontSize: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <h1 className="text-oswald-font pt-4">
                  <span className="text-light-green">
                    {zoneInfo[0].sustainability.title1}
                  </span>
                  <span className="">{zoneInfo[0].sustainability.title2}</span>
                </h1>
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: 20,
                    lg: 15,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <p className="pt-4 font-['Open_Sans']">
                  {zoneInfo[0].sustainability.description}
                </p>
              </Typography>
            </div>
            {/*</Grow>*/}
          </Grid>
          <Grid xs={6} md={4}>
            <div className="pl-12 pt-4">
              <LifeCyle
                query={store.queries.dataQuery}
                variables={{ table: "sustainability", schema: zones }}
                processor={sustainabilityData.preProcessor}
                loader={<GlobeLoading />}
              >
                {(data) =>
                  data.map((indicator) => (
                    <OverviewCard
                      value={indicator.sum}
                      description={indicator.Indicator}
                      type={indicator.type}
                    />
                  ))
                }
              </LifeCyle>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div className="w-full">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            style={{ fontWeight: currentTab === `${tab.id}` && "bolder" }}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p>{tab.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Sustainability
