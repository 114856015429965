import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Bar } from "react-chartjs-2"

import store from "../Store"

const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
  (el) => el.key === "currency-format"
)[0]

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      color: "white",
      formatter: function (value) {
        return currencyFormat.preProcessor(value, currencyFormat.formatLookup)
        // value.toLocaleString()
      },
    },
    title: {
      display: false,
    },
  },
  animation: {
    duration: 2000,
  },
  scales: {
    x: {
      grid: {
        display: false,
        borderWidth: 5,
        color: "rgb(32, 83, 214)",
      },
    },
    y: {
      grid: {
        display: false,
        tickLength: 0,
        borderWidth: 0,
        drawBorder: false,
      },
      display: false,
      ticks: {
        display: false,
      },
    },
  },
}

const Barchart = ({ labels, data_, otherOptions }) => {
  const data = {
    labels,
    datasets: [
      {
        data: data_,
        backgroundColor: "#2f2f7D",
      },
    ],
  }
  return (
    <div className="canvas-container" style={{ height: "80%" }}>
      <Bar options={{...options, ...otherOptions}} data={data} />
    </div>
  )
}
export default Barchart
