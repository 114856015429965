import React from "react"
import Login from "../components/Login"
//flex content-center items-center justify-center
//"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-2 md:gap-4"
//h-full object-cover mr-10 w-1/2
//flex content-center"
const LoginPage = () => {
  return (
    <main>
      <div className="bg-white">
        <div className="grid grid-cols-8">
          <div className="col-start-2 col-end-4">
            <Login />
          </div>
          <div className="col-start-5 col-span-3">
            <div className="h-screen bg-no-repeat bg-login-img bg-cover bg-center"></div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default LoginPage
