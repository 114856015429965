import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js"
import { Doughnut } from "react-chartjs-2"
import gaugeButton from "../resources/img/GaugeButton.png"

const finalValue = (value) => {
  return `${value.toFixed(1)}%`
}

const gaugeChartText = {
  id: "gaugeChartText",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      data,
      chartArea: { top, bottom, left, right, width, height },
      scales: { r },
    } = chart

    ctx.save()
    const xCoordinate = chart.getDatasetMeta(0).data[0].x
    const yCoordinate = chart.getDatasetMeta(0).data[0].y

    ctx.font = "bold 20px sans-serif"
    ctx.fillStyle = "#2F2F7D"
    ctx.textAlign = "center"
    ctx.fillText(finalValue(data.datasets[0].data[0]), xCoordinate, yCoordinate)
  },
}
const radioImage = new Image(100, 100)
radioImage.src = gaugeButton

const gaugeChartIcon = {
  id: "gaugeChartIcon",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      data,
      chartArea: { top, bottom, left, right, width, height },
    } = chart

    ctx.save()

    const needleValue = data.datasets[0].needleValue
    const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0)

    // given the donut radius (innerRadius) and donut radius (radius)
    const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius
    const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius
    var midRadius = innerRadius + (outerRadius - innerRadius) / 2

    const xCoordinate = chart.getDatasetMeta(0).data[0].x
    const yCoordinate = chart.getDatasetMeta(0).data[0].y

    const startAngle = chart._metasets[0].data[0].startAngle
    const endAngle = chart._metasets[0].data[0].endAngle
    // needle dot

    ctx.beginPath()
    ctx.moveTo(...getPoint(xCoordinate, yCoordinate, midRadius, endAngle))
    ctx.arc(
      ...getPoint(xCoordinate, yCoordinate, midRadius, endAngle),
      (outerRadius - innerRadius) / 2,
      0,
      10
    )
    ctx.fill()
    ctx.restore()
  },
}

ChartJS.register(ArcElement, Tooltip)

export const options = {
  maintainAspectRatio: false,
  clip: false,
  plugins: {
    title: {
      display: false,
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    padding: {
      top: 30,
      bottom: 30,
    },
    responsive: true,
    animation: {
      animateScale: true,
    },
    tooltip: {
      enabled: false,
    },
  },
}

const GaugeChart = ({ labels, data_ }) => {
  // data_ = [80, 20]
  const data = {
    labels,
    datasets: [
      {
        data: data_,
        backgroundColor: ["#CED4DA"],
        borderColor: ["#CED4DA"],
        circumference: 270,
        rotation: -135,
        cutout: "80%",
        needleValue: data_[0],
      },
    ],
  }
  return (
    <div
      style={{
        backgroundColor: "#E9ECEF",
        padding: "10px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Doughnut
        options={options}
        data={data}
        plugins={[gaugeChartText, gaugeChartIcon]}
        width="100%"
      />
    </div>
  )
}

function getPoint(c1, c2, radius, angle) {
  return [c1 + Math.cos(angle) * radius, c2 + Math.sin(angle) * radius]
}
export default GaugeChart
