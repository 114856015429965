import React, { useContext, useState} from "react"
import classNames from "classnames"
import { withStyles } from "@mui/styles"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import CssBaseline from "@mui/material/CssBaseline"
import ListItem from "@mui/material/ListItem"
import ZonesContainer from "../containers/ZonesContainer"
import gabonlogo from "../resources/img/gabon.png"
import togoLogo from "../resources/img/togo.png"
import beninLogo from "../resources/img/benin.png"
import SideNavButton from "../elements/SideNavButton"
import impactLogo from "../resources/img/track_changes.svg"
import economicLogo from "../resources/img/chart_data.svg"
import jobsLogo from "../resources/img/work.svg"
import valueLogo from "../resources/img/join_inner.svg"
import sectorLogo from "../resources/img/auto_activity_zone.svg"
import fiscalLogo from "../resources/img/account_balance.svg"
import sustainbilityLogo from "../resources/img/eco.svg"
//import { ZonePageContext } from "../states/ZonesPageContext"
import { makeStyles, Theme } from "@mui/styles"
import sideNavBg from "../resources/img/side_nav_bg2.jpg"

const drawerWidth = 240

const styles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    top: "100px",
    backgroundColor: "#cfd8dc",
    backgroundImage: `url(${sideNavBg})`,
    backgroundSize: "contain",
  },
  drawerOpen: {
    width: drawerWidth,
    top: "100px",
  },
  drawerClose: {
    width: drawerWidth,
    //overflowX: "hidden",
    top: "98px",
    //padding:"8px 0",
    backgroundColor: "#cfd8dc",
    backgroundImage: `url(${sideNavBg})`,
    backgroundSize: "contain",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    marginTop: 3,
    justifyContent: "flex-end",
    padding: "0 8px",
    //...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: 3,
    backgroundColor: "white",
  },
  grow: {
    flexGrow: 1,
  },
}))

const SideMain1 = ({ zones }) => {
  const classes = styles()
  const [open, setOpen] = useState(false)
  //const { tabs } = useContext(ZonePageContext)
  //const [view] = tabs
  //const currentPage = window.location.hash.split('#')[1].replaceAll("%20"," ")
  //let currentPage = ''
  //if (window.location.hash.split('#')[1] === 'undefined'){
  //  currentPage = ''
  //}else{
  //  console.log(window.location.hash.split('#')[1])
  //  currentPage = window.location.hash.split('#')[1].replaceAll("%20"," ")
  //}

  const logo =
    zones === "gabon" ? gabonlogo : zones === "togo" ? togoLogo : beninLogo
  const impact = zones === "gabon" ? "gsez" : zones === "togo" ? "pia" : "gdiz"

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open,
          //[classes.drawerClose]: !this.state.open
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className="" />
        <List>
          <ListItem
            sx={{
              paddingTop: {
                xl: 2,
                lg: 2,
                md: 2,
                sm: 2,
                xs: 2,
              },
              paddingBottom: {
                xl: 2,
                lg: 2,
                md: 2,
                sm: 2,
                xs: 2,
              },
            }}
            style={{ display: "flex", justifyContent: "flex-center" }}
          >
              <img
                src={logo}
                className="object-scale-down h-12 m-auto"
                alt="logo"
                onClick={() => window.open(`https://www.ariseiip.com/project/${impact}/`)}
              />
          </ListItem>
          <SideNavButton logo={impactLogo} title="Impact Overview" />
          <SideNavButton logo={economicLogo} title="Economic Value" />
          <SideNavButton logo={jobsLogo} title="Jobs" />
          <SideNavButton logo={valueLogo} title="Value Addition" />
          <SideNavButton logo={sectorLogo} title="Sector Diversification" />
          <SideNavButton logo={fiscalLogo} title="Fiscal Impact" />
          <SideNavButton logo={sustainbilityLogo} title="Sustainability" />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className="pl-5">
          <div className={classes.toolbar} />
          {/*<div className="mt-2 mb-2 xl:mt-4 xl:mb-4">
          <Typography variant="caption">{currentPage}{" "}/{" "}{view}</Typography>
              </div>*/}
          <ZonesContainer zones={zones} />
        </div>
      </main>
    </div>
  )
}
export default withStyles(styles, { withTheme: true })(SideMain1)
