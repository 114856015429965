import React, { useContext } from "react"
import { PagesContext } from "../states/PagesContext"
import styled from "styled-components"
import MovingComponent from "react-moving-text"
import Carousel from "react-bootstrap/Carousel"
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import image1 from "../resources/img/landing1.png"
import image2 from "../resources/img/landing2.png"
import image3 from "../resources/img/landing3.png"
import "bootstrap/dist/css/bootstrap.min.css"

const CoverPage = () => {
  const { mainViewState } = useContext(PagesContext)
  const [mainView, setMainView] = mainViewState

  const Button = styled.button`
    background-color: #2f2f7d;
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    margin: 10px 0px;
    cursor: pointer;
  `

const styles = {
  heroContainer: {
    height: 900,
    backgroundImage: `url(${image1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: `calc(100vw + 72px)`,
    margin: -24,
    padding: 24,
    brightness:50,

  }
 };

 const styles2 = {
  heroContainer: {
    height: 900,
    backgroundImage: `url(${image2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: `calc(100vw + 72px)`,
    margin: -24,
    padding: 24,
    brightness:50,

  }
 };

 const styles3 = {
  heroContainer: {
    height: 900,
    backgroundImage: `url(${image3})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: `calc(100vw + 72px)`,
    margin: -24,
    padding: 24,
    brightness:50,

  }
 };

  return (
      <main>
<Carousel autoPlay={true} interval={5000}>
<Carousel.Item>
<Grid
    container
    direction="column"
    //justify="center"
    //alignItems="center"
    style={styles.heroContainer} >
   <Carousel.Caption className="carousel-caption">
    <div class="text-center pt-15">
      <p className="text-4xl lg:text-8xl xl:text-8xl text-oswald-font text-white pb-6">
                    WELCOME TO THE <br /> ARISE IIP IMPACT PORTAL
                  </p>
                  <Button className="text-oswald-font"
              onClick={() => setMainView("Dashboard")}
            >
              Discover ARISE IIP Impact
            </Button>
            </div>
            </Carousel.Caption>
</Grid>
</Carousel.Item>

<Carousel.Item>
<Grid
    container
    direction="column"
    //justify="center"
    //alignItems="center"
    style={styles2.heroContainer} >
   <Carousel.Caption className="carousel-caption">
   <div class="text-center pt-15 xs:pt-10">
      <p className="text-4xl lg:text-8xl xl:text-8xl text-oswald-font text-white pb-6">
                COMMITTED TO MAKING
                <br /> AFRICA THRIVE
                  </p>
                  <Button className="text-oswald-font"
              onClick={() => setMainView("Dashboard")}
            >
              Discover ARISE IIP Impact
            </Button>
            </div>
            </Carousel.Caption>
</Grid>
</Carousel.Item>

<Carousel.Item>
<Grid
    container
    direction="column"
    //justify="center"
    //alignItems="center"
    style={styles3.heroContainer} >
   <Carousel.Caption className="carousel-caption">
    <div class="text-center pt-15 xs:pt-10">
      <p className="text-4xl lg:text-8xl xl:text-8xl text-oswald-font text-white pb-6">
                    BUILDING INTEGRATED <br /> ECOSYSTEMS
                  </p>
                  <Button className="text-oswald-font"
              onClick={() => setMainView("Dashboard")}
            >
              Discover ARISE IIP Impact
            </Button>
            </div>
            </Carousel.Caption>
</Grid>
</Carousel.Item>
</Carousel>
</main>
  )
}
export default CoverPage
