import React, { useContext } from "react";
import styled from "styled-components";
import PageEndIcon from "@rsuite/icons/PageEnd";
import { PagesContext } from "../states/PagesContext";
import "../resources/styles/styles.css";
import { Router, useHref, Link } from "react-router-dom";

const Button = styled.button`
  background-color: #2f2f7d;
  color: white;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: bold;
  margin: auto;
  width: 100%;
`;

const IndicatorCard = (image, title, link, text) => {
  const { mainViewState } = useContext(PagesContext);
  const [mainView, setMainView] = mainViewState;

  return (
    <div className="indicator-card">
      <div className="flex flex-col justify-center text-left">
          <img src={image} className="logo-img" alt=""
          onClick={() => window.open(link)}/>
        <p className="my-3 font-body-text">
         {text}
        </p>
        <Button
          onClick={() => {
            setMainView(title);
          }}
        >
          <PageEndIcon size="4em" /> Explore
        </Button>
      </div>
    </div>
  );
};

export default IndicatorCard;
