import store from "../Store";
import _ from "lodash";

export function getPageData(data, page) {
  var dataValues = data.body.data;
  var pageData = dataValues
    .map((data) => data)
    .filter((obj) => obj.Page === page);
  return pageData;
}

export function economicOverview({ data }) {
  let data_ = [];

  const numberValues = [
    "Jobs created directly and indirectly in the market",
    "Sites audited",
    "Approximate quantity of timber inspected to date",
    "Number of active firms",
    "Hectares of Forest Certified",
    "Industrial Units FSC Certified",
    "Dyeing and Ready Fabric Units Certified ",
    "Jobs created during construction"
  ];
  const percentValues = [
    "% growth per annum of non-wood",
    "% growth per annum of non-agri sectors",
  ];

  const output = data.map((data1) => {
    if (data1.Indicator.includes("growth per annum of non-wood")) {
      data1["type"] = "percent";
      data1["Indicator"] = "growth per annum of non-wood";
    } else if (
      data1.Indicator.includes("growth per annum of non-agri sectors")
    ) {
      data1["type"] = "percent";
      data1["Indicator"] = "growth per annum of non-agri sectors";
    } else if (numberValues.includes(data1.Indicator)) {
      data1["type"] = "count";
    } else if (
      data1.Indicator ===
      "Value created in the economy for all USD spent by tenants"
    ) {
      data1["type"] = "X";
    } else {
      data1["type"] = "currency";
    }

    data_.push(data1);
  });
  return data_;
}

export function economicExplore(data) {
  console.log(data, "============================2526");
  const total = [];
  const year = [];
  let output_ = {};

  // sort data by year
  data.data.sort((p1, p2) =>
    p1.year < p2.year ? -1 : p1.year > p2.year ? 1 : 0
  );

  const currentYear = new Date().getFullYear();
  const data_ = data.data.filter((item) => item.year <= currentYear);

  const output = data_.map((data1) => {
    total.push(Math.ceil(data1.sum));
    year.push(data1.year);
  });
  output_["data"] = total;
  output_["label"] = year;
  return [output_];
}

export function sustainabilityExploreGauge({ data }) {
  // sort data by year
  //data.sort((p1, p2) => (p1.year < p2.year ? -1 : p1.year > p2.year ? 1 : 0))

  //const currentYear = new Date().getFullYear()
  //const data_ = data.filter((item) => item.year <= currentYear)

  var sum = 0;
  const output = data.map((data1) => {
    sum += data1.sum;
    return sum;
  });

  //const average = sum / Object.keys(data_).length
  //output_["sum"] = average > 0 ? average : undefined

  // return data from most recent year
  //output_ = data_.length > 0 ? data_[data_.length - 1] : {}
  console.log(
    [{ sum: sum * 100, year: 2023 }],
    "============================="
  );

  return [{ sum: sum * 100, year: 2023 }];
}
export function jobsExploreMain(data) {
  // sort data by year
  data.data.sort((p1, p2) =>
    p1.year < p2.year ? -1 : p1.year > p2.year ? 1 : 0
  );
  const currentYear = new Date().getFullYear();
  data.data = data.data.filter((item) => item.year <= currentYear);

  const total = [];
  const year = [];
  const label = [];
  let output_ = {};
  const output = data.data.map((data1) => {
    total.push(Math.ceil(data1.sum));
    year.push(data1.year);
    label.push(data1.Indicator);
  });
  output_["data"] = total;
  output_["year"] = year;
  output_["label"] = label;
  console.log(data, "Value Addition output data======");
}

export function jobsExplore(data) {
  // sort data by year
  data.data.sort((p1, p2) =>
    p1.year < p2.year ? -1 : p1.year > p2.year ? 1 : 0
  );
  const currentYear = new Date().getFullYear();
  data.data = data.data.filter((item) => item.year <= currentYear);

  const total = [];
  const year = [];
  const label = [];
  let output_ = {};
  const output = data.data.map((data1) => {
    total.push(Math.ceil(data1.sum));
    year.push(data1.year);
    label.push(data1.Indicator);
  });
  output_["data"] = total;
  output_["year"] = year;
  output_["label"] = label;
  console.log(data, "Value Addition output data======");

  const plottingData = [];
  [output_].map((indicator) => {
    const labels = [...new Set(output_["label"])];
    const labelIndices = labels.map((label) =>
      output_.label.reduce(function (a, e, i) {
        if (e === label) a.push(i);
        return a;
      }, [])
    );

    labels.map((label, index) =>
      plottingData.push({
        label: label.includes("Total wages by gender")
          ? label.split("- ")[1]
          : label,
        data: labelIndices[index].map((i) => indicator.data[i]),
      })
    );
    plottingData.sort((a, b) => sumTotal(b.data) - sumTotal(a.data));
  });
  // return [output_]
  return { data: plottingData, labels: [...new Set(year)] };
}

export function sectorsExplore(data) {
  const total = [];
  const year = [];
  const label = [];
  let output_ = {};
  let sortedData = _.sortBy(data.data, "year");
  sortedData = _.filter(sortedData, (o) => o.sum != 0);
  sortedData = _.filter(sortedData, (o) => o.Sector != "All sectors");
  const output = sortedData.map((data1) => {
    total.push(Math.ceil(data1.sum));
    year.push(data1.year);
    label.push(data1.Sector);
  });
  output_["data"] = total;
  output_["year"] = year;
  output_["label"] = label;

  const plottingData = [];
  [output_].map((indicator) => {
    const labels = [...new Set(output_["label"])];
    const labelIndices = labels.map((label) =>
      output_.label.reduce(function (a, e, i) {
        if (e === label) a.push(i);
        return a;
      }, [])
    );

    labels.map((label, index) =>
      plottingData.push({
        label: label,
        data: labelIndices[index].map((i) => indicator.data[i]),
      })
    );
    plottingData.sort((a, b) => sumTotal(b.data) - sumTotal(a.data));
  });
  // return [output_]
  return { data: plottingData, labels: [...new Set(year)] };
}

export function getOverviewData({ data, schema }) {
  console.log(schema);
  const overviewValues = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.overviewZone === schema
  )[0];

  var indicatorList = overviewValues.listIndicators;
  let op = indicatorList.map((e, i) => {
    let temp = data.find(
      (element) => element.Indicator.trim() === e.indicator.trim()
    );
    e.sum = temp.sum;
    return e;
  });

  console.log(op, "========================data===========");
  return op;
}

export function nFormatter(num, lookup) {
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

function sumTotal(obj) {
  return obj.reduce((acc, curr) => acc + curr, 0);
}
