import React from "react"
import store from "../Store"
import Typography from "@mui/material/Typography"

// Might need to add a prop that tags the page where overview card is used.

const OverviewCard = ({ value, description, type }) => {
  const finalValue = (value) => {
    if (type === "currency") {
      return `$${currencyFormat.preProcessor(
        value,
        currencyFormat.formatLookup
      )}`
    } else if (type === "count") {
      return `${currencyFormat.preProcessor(
        value.toFixed(0),
        currencyFormat.formatLookup
      )}`
    } else if (type === "percent") {
      return `${(value * 100).toFixed(1)}%`
    } else {
      return `${value.toFixed(1)}X`
    }
  }

  const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "currency-format"
  )[0]

  const updatedDescription = description == "Revenue generated in the economy"? `${description} since 2018` :description  // Change added on BK's request

  return (
    <div className="overflow-hidden flex flex-col">
      <Typography
        component="div"
        variant="h2"
        sx={{
          fontSize: {
            xl: 40,
            lg: 30,
            md: 25,
            sm: 20,
            xs: 20,
          },
          fontWeight: "900",
          color: "#2f2f7D",
          marginBottom: "5px",
          fontFamily: "Oswald",
        }}
      >
        {finalValue(value)}
      </Typography>
      <Typography
        sx={{
          fontSize: {
            xl: 20,
            lg: 15,
            md: 15,
            sm: 15,
            xs: 10,
          },
          fontFamily: '"Open Sans"',
        }}
      >
        <div className="mt-auto pb-4">
          <p>{updatedDescription.charAt(0).toUpperCase() + updatedDescription.slice(1)}</p>
        </div>
      </Typography>
    </div>
  )
}

export default OverviewCard
