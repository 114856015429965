import React, { useRef } from "react";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Grid, TextField, CardContent, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ContactCard from "../elements/contactCard";
import proPic from "../resources/img/Bhavin.jfif";
import emailjs from "@emailjs/browser";

const useStyles = makeStyles((theme) => ({
  inputField: {
    fontFamily: '"Open Sans"',
    fontSize: "8px",
  },
}));
const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z75b2h6",
        "template_q61th0i",
        form.current,
        "wOnYAx3Bw_FCzlWHm"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
        
      );
      e.target.reset()
    
  };

  const theme = createTheme({
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 8,
        },
      },
    },
    typography: {
      fontFamily: ['"Open Sans"'].join(","),
    },
  });

  const classes = useStyles();
  const Button = styled.button`
    background-color: #2f2f7d;
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    margin: 10px 0px;
    cursor: pointer;
    raduis: 35;
  `;

  return (
    <div className="flex flex-col items-center justify-center h-full w-full text-center">
      <Grid container spacing={1}>
        <Grid sm={1} item></Grid>
        <Grid xs={12} sm={6} alignItems="center" justifyContent="center" item>
          <div className="py-8">
            <ThemeProvider theme={theme}>
              <Typography
            
                sx={{
                  //fontWeight: "200",
                  color: "#2f2f7D",
                  marginBottom: "8px",
                  fontFamily: '"Open Sans"',

                }}
                variant="h5"
              >
                PLEASE COMPLETE THE FOLLOWING FORM
              </Typography>
            </ThemeProvider>
            <CardContent
           
            >
              <form ref={form} onSubmit={sendEmail}>
                <Grid container spacing={1}>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      name="fname"
                      className={classes.inputField}
                      placeholder="Enter first name"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      name="lname"
                      className={classes.inputField}
                      placeholder="Enter last name"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      className={classes.inputField}
                      type="email"
                      placeholder="Enter email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                   {/*
                  <Grid item xs={12}>
                    <PhoneInput
                      name="phone"
                      country={"us"}
                      placeholder="Enter phone number"
                      containerStyle={{
                        height: "50px",
                      }}
                      inputStyle={{
                        height: "50px",
                        width: "100%",
                      }}
                      //value={value}
                      //onChange={setValue}
                    />
                  </Grid>
                  */}
              
                  <Grid xs={12} sm={6} item>
                    <TextField
                      name="sector"
                      className={classes.inputField}
                      placeholder="Interest in Sector(s)"
                      label="sector(s)"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid xs={12} sm={6} item>
                    <TextField
                      name="zones"
                      className={classes.inputField}
                      placeholder="Interest in Zone(s)"
                      label="zone(s)"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="message"
                      className={classes.inputField}
                      label="Message"
                      multiline
                      rows={4}
                      placeholder="Type your message here"
                      variant="outlined"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} justify="flex-end">
                    <Button type="submit"> Submit</Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </div>
        </Grid>
        <Grid xs={12} sm={1} item></Grid>
        <Grid xs={12} sm={4} item>
          <div className="py-8">
            <Typography
              component="div"
              variant="h5"
              //align="left"
              sx={{
                //fontWeight: "200",
                color: "#2f2f7D",
                marginBottom: "8px",
                fontFamily: '"Open Sans"',
              }}
            >
              GLOBAL CONTACT
            </Typography>

            <ContactCard
              image={proPic}
              name="Bhavin Vyas"
              position="Chief ESG Officer – ARISE IIP, IS & PL"
              email="bhavin.vyas@arisenet.com"
              location="ARISE IIP Managing Office Ltd, Unit N1203 - Level 12,
          Emirates Financial Towers, Dubai International Financial Center, UAE"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
