import React from "react"
import MovingComponent from "react-moving-text"
import Typography from "@mui/material/Typography"
import IndicatorCard from "../components/IndicatorCard"
import gabon from "../resources/img/gabon.png"
import benin from "../resources/img/benin.png"
import togo from "../resources/img/togo.png"
import Grow from "@mui/material/Grow"
import Link from "@mui/material/Link"
const Dashboard = () => {
  //onClick={() => window.open(`https://www.ariseiip.com/project/${impact}/`)}
  const iconsList = [
    {
      icon: gabon,
      title: "GSEZ impact",
      link: "https://www.gsez.com/",
      text: "GSEZ - Launched in 2010, GSEZ was set up to foster local timber processing, industrial production, and the sustainable use of Gabon’s natural resources, primarily timber, for local and export markets."
    },
    {
      icon: benin,
      title: "GDIZ impact",
      link: "https://gdiz-benin.com/",
      text: "GDIZ - The Glo-Djigbé Industrial Zone (GDIZ) is dedicated to the local transformation of agricultural product including cotton, cashews, pineapples, shea nuts and soybeans, among others."
    },
    {
      icon: togo,
      title: "PIA impact",
      link: "https://pia-togo.com/",
      text: "PIA - PIA is a vertically integrated industrial zone, focused at creating thriving value chains for storage, processing, manufacturing, and export."
    },
  ]
  const bodyProps = {
    variant: "body1",
    style: { fontFamily: '"Open Sans"', color: "black", lineHeight: 1.4 },
    gutterBottom: true,
  }
  return (
    <main>
      <div className="bg-white border-0 px-48 flex flex-col text-center justify-center items-center">
        <div className="py-8">
          <MovingComponent
            type="fadeInFromLeft"
            duration="1000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="1"
            fillMode="none"
          >
            <h1 className="font-title  text-2xl sm:text-xl md:text-2xl lg:text-4xl">
              <span className="font-extrabold text-light-green">About </span>
              <span className="font-extrabold text-arise-blue">ARISE IIP</span>
            </h1>
          </MovingComponent>
        </div>
        <div className="px-10 md:px-10">
          <Grow in={true} timeout={1100}>
            <Typography {...bodyProps}>
              <Link
                href="https://www.ariseiip.com/"
                target="_blank"
                rel="noreferrer"
                underline="none"
              >
                Arise IIP{" "}
              </Link>{" "}
              identifies industrial gaps in African countries that unlock value
              and create new industries. Our aim is to industrialize key sectors
              by creating local transformation, maximizing production,
              efficiency and cost, which in turn generates local value addition.
              Our trusted partnerships with governments and investors enable us
              to execute large-scale projects that transform local value chains
              and industrialize countries in an environmentally sustainable way.
              Our tailor-made special economic zones in{" "}
              <span className="text-light-green">Gabon</span>,{" "}
              <span className="text-light-green">Benin</span> and{" "}
              <span className="text-light-green">Togo</span> seek to boost
              exports, enable the local transformation of raw materials and
              promote trade.
            </Typography>
          </Grow>
        </div>
        <div className="py-8">
          <div className="flex flex-col items-center">
            <MovingComponent
              type="fadeInFromLeft"
              duration="1000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              <h1 className="font-title text-2xl sm:text-xl md:text-2xl lg:text-4xl">
                <span className="font-extrabold text-light-green">
                  Select the impact of
                </span>
                <span className="font-extrabold text-arise-blue">
                  {" "}
                  ARISE IIP SEZs
                </span>
              </h1>
            </MovingComponent>
            <p className="font-body-text">
              Click to explore the Special Economic Zones impact
            </p>
          </div>
        </div>
        <MovingComponent
          type="fadeInFromBottom"
          duration="3000ms"
          delay="0s"
          direction="normal"
          timing="ease"
          iteration="1"
          fillMode="none"
        >
          <div className="flex justify-between px-20 pb-10">
            {iconsList.map((items, key) =>
              IndicatorCard(items.icon, items.title, items.link, items.text)
            )}
          </div>
        </MovingComponent>
      </div>
    </main>
  )
}

export default Dashboard
