import React from "react"
import { ToggleButtonGroup } from "@mui/material"
import MuiToggleButton from "@mui/material/ToggleButton"
import { styled } from "@mui/material/styles"

const toggleProps = {
  variant: "h6",
  style: {
    fontFamily: '"Open Sans"',
    lineHeight: 1.4,
    padding: "10px",
  },
  gutterBottom: true,
}

const ToggleButtons = ({
  setIndicator,
  indicator,
  totalOff = false,
  directOff = false,
  indirectOff = false,
}) => {
  const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: selectedColor,
    },
  }))
  return (
    <ToggleButtonGroup
      value={indicator}
      exclusive
      onChange={setIndicator}
      aria-label="Platform"
      size="small"
      // fullWidth
    >
      {!totalOff && (
        <ToggleButton value="Total " selectedColor="#9ACA3C" {...toggleProps}>
          Total
        </ToggleButton>
      )}
      {!directOff && (
        <ToggleButton value="Direct" selectedColor="#9ACA3C" {...toggleProps}>
          Direct
        </ToggleButton>
      )}
      {!indirectOff && (
        <ToggleButton value="Indirect" selectedColor="#9ACA3C" {...toggleProps}>
          Indirect
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  )
}

export default ToggleButtons
