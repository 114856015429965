import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import OverviewCard from "../elements/OverviewCards"
import LifeCyle from "../model/LifeCycle"
import store from "../Store"
import GlobeLoading from "../elements/GlobeLoading"
import Dropdown from "../elements/Dropdown"
import CheckBoxDropdown from "../elements/CheckboxDropdown"
import Typography from "@mui/material/Typography"
import ToggleButtons from "../elements/ToggleButtons"
import { filterQuery } from "../model/Query"
import Barchart from "../components/Barchart"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Button from "@mui/material/Button"
import DialogPopup from "../components/DialogPopup"

const dropdownProps = {
  variant: "h6",
  style: {
    fontFamily: '"Open Sans"',
    color: "black",
    lineHeight: 1.4,
    fontSize: "1.5rem",
  },
  gutterBottom: true,
}

const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
  (el) => el.key === "currency-format"
)[0]

const EconomicValue = ({ zones }) => {
  const economicValueData = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "economic-overview"
  )[0]
  const zoneInfo = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.zone === zones
  )

  const defaultYearList = zones == 'gabon'? ["2017","2018", "2019", "2020", "2021"]:["2022"]

  const [currentTab, setCurrentTab] = useState("1")
  const buttonsValues = ["Total ", "Direct", "Indirect"]
  const [active, setActive] = useState(buttonsValues[0])
  const [sector, setSector] = useState("All Sectors")
  const [indicator, setIndicator] = useState("Economic value added")
  const [years, setYears] = useState(defaultYearList)
  const [yearsList, setYearsList] = useState([])
  const [sectorList, setSectorList] = useState([])
  const [indicatorList, setIndicatorList] = useState([])
  const selected_all = [
    "FDI attracted annually",
    "Exports generated",
    "Revenues generated",
    "Economic value added",
  ]

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setActive(buttonsValues[0])
  }

  console.log(`Active: ${active == "Total "}`)
  console.log(`Indicator: ${indicator} `)

  const queryFlag =
    indicator == "Revenues generated"
      ? true
      : active == "Total "
      ? true
      : false

  const Yearsvariables = {
    variables: { table: "economic_value", filter: "year", schema: zones },
  }
  const Sectorvariables = {
    variables: { table: "economic_value", filter: "Sector", schema: zones },
  }
  const Indicatorvariables = {
    variables: { table: "economic_value", filter: "Indicator", schema: zones },
  }
  const tabs = [
    {
      id: 1,
      tabTitle: "Overview",
      content: overviewData(),
    },
    {
      id: 2,
      tabTitle: "Explore Data",
      content: exploreData(),
    },
  ]
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  function handleToggleChange(event) {
    setActive(event.target.value)
  }

  function Center({ children }) {
    return (
      <div
        style={{
          display: "flex-col",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    )
  }

  function exploreData() {
    return (
      <div>
        <Grid container columnSpacing={2} rowSpacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Sector</p>
            <Dropdown
              setIndicator={setSector}
              indicatorList={sectorList}
              indicator={sector}
              id="sector"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">
              Indicator
            </p>
            <Dropdown
              setIndicator={setIndicator}
              indicatorList={selected_all}
              indicator={indicator}
              id="indicator"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Year</p>
            <CheckBoxDropdown
              setIndicator={setYears}
              indicatorList={yearsList}
              indicator={years}
              id="year"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ToggleButtons
              setIndicator={handleToggleChange}
              indicator={active}
            />
          </Grid>
        </Grid>
        <div className="pt-4 h-1/2">
          {queryFlag && (
            <LifeCyle
              query={store.queries.economicQuery}
              variables={{
                table: "economic_value",
                indicator: indicator,
                sector: sector,
                years: years,
                sub_sector: active,
                tab: "economic_value",
                schema: zones,
              }}
              processor={economicValueData.preProcessor2}
              loader={<GlobeLoading />}
            >
              {(data) =>
                data.map((indicator) => (
                  <Barchart data_={indicator.data} labels={indicator.label} otherOptions={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        color: "white",
                        formatter: function (value) {
                          return `$${currencyFormat.preProcessor(
                            value,
                            currencyFormat.formatLookup
                          )}`
                        },
                      },
                      title: {
                        display: false,
                      },
                    },
                  }} />
                ))
              }
            </LifeCyle>
          )}
          {!queryFlag && (
            <Dialog
              open={true}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                {indicator == "Economic value added" && (
                  <DialogContentText id="alert-dialog-description">
                    Economic value added is an overall estimation of the direct
                    and indirect impact of the economy, and cannot be decoupled
                    or broken down into these component parts
                  </DialogContentText>
                )}
                {indicator == "FDI attracted annually" && (
                  <DialogContentText id="alert-dialog-description">
                    FDI attracted annually is based on FDI attracted by firms in
                    the zone; further decoupling into direct and indirect would
                    require significant assumptions
                  </DialogContentText>
                )}
                {indicator == "Exports generated" && (
                  <DialogContentText id="alert-dialog-description">
                    Exports generated is based on exports by firms in the zone;
                    further decoupling into direct and indirect would require
                    significant assumptions on second order effects and exports
                  </DialogContentText>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          )}

          <DialogPopup zones={zones} />
        </div>
      </div>
    )
  }
  useEffect(() => {
    const getDropdownLists = async () => {
      const years_ = await filterQuery(Yearsvariables)
      const sectors_ = await filterQuery(Sectorvariables)
      const indicators_ = await filterQuery(Indicatorvariables)
      setYearsList(years_)
      setSectorList(sectors_)
      setIndicatorList(indicators_)
    }
    getDropdownLists()
  }, [])

  function overviewData() {
    return (
      <div>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid xs={6} md={8}>
            <div className="pl-10">
              <Typography
                sx={{
                  fontSize: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <h1 className="text-oswald-font pt-4">
                  <span className="text-light-green">
                    {zoneInfo[0].economic.title1}
                  </span>
                  <span className="">{zoneInfo[0].economic.title2}</span>
                </h1>
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: 20,
                    lg: 15,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <p className="pt-4 font-['Open_Sans']">
                  {zoneInfo[0].economic.description}
                </p>
              </Typography>
            </div>
            {/*</Grow>*/}
          </Grid>
          <Grid xs={6} md={4}>
            <LifeCyle
              query={store.queries.dataQuery}
              variables={{ table: "economic_value", schema: zones }}
              processor={economicValueData.preProcessor}
              loader={<GlobeLoading />}
            >
              {(data) =>
                data.map((indicator) => (
                  <div className="pl-12 pt-4">
                    <OverviewCard
                      value={indicator.sum}
                      description={indicator.Indicator}
                      type={indicator.type}
                    />
                  </div>
                ))
              }
            </LifeCyle>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div className="w-full">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            style={{ fontWeight: currentTab === `${tab.id}` && "bolder" }}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p>{tab.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default EconomicValue
