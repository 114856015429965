import React from "react";
import MethodologyPage from "./MethodologyPage";

const AboutPage = () => {
  return (
    <main className="">
      <div className="flex flex-col items-center justify-center h-full w-full text-center">
        <MethodologyPage />
      </div>
    </main>
  );
};

export default AboutPage;
