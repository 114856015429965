import React, { useState} from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Button from "@mui/material/Button"



const DialogPopup = ({zones}) => {
  const [openTogoBenin, setOpenTogoBenin] = useState(true)
  const handleCloseBeninTogo = () =>{
    setOpenTogoBenin(false)
  }

  return (
    <>
      {zones != "gabon" && openTogoBenin == true && (
        <Dialog
        open={true}
        onClose={handleCloseBeninTogo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Firms are operationalizing in the zone! Updates on their performance and impact will be uploaded over time.
            </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseBeninTogo}>Close</Button>
        </DialogActions>
        </Dialog>
      )}
    </>
   )
  }

export default DialogPopup;
