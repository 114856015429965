const tab = Object.freeze({
  Overview: "Impact Overview",
  Economic: "Economic Value",
  Beneficiation: "Value Addition",
  Diversification: "Sector Diversification",
  Fiscal: "Fiscal Impact",
  Sustainability: "Sustainability",
  JobsPage: "Jobs"
});
module.exports = tab;
