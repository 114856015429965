import * as transformations from "./transformations";
import economic_value from "../resources/img/economic_value.png";
import jobs from "../resources/img/jobs.png";
import value_addition from "../resources/img/value_addition.png";
import sector_diversification from "../resources/img/sector_diversification.png";
import fiscal_impact from "../resources/img/fiscal_impact.png";
import sustainability from "../resources/img/sustainability_2.png";
import beninEconomic from "../resources/img/Economic_Value.jpg";
import beninSector from "../resources/img/SectorDiversification.png";
import togoValue_addition from "../resources/img/valueAddition.png";
import togoSustainability from "../resources/img/Sustainability.jpg";
import togoJobs from "../resources/img/Jobs.jpg";
import togoFiscalImpact from "../resources/img/Fiscal_Impact.JPG";
import togoEconomic from "../resources/img/Economic_Value_Added.JPG";

//import store from "../Store"

const indiactorMeta = [
  {
    key: "overview-tab",
    page: "landing-page",
    variables: { table: "impact_overview" },
    preProcessor: transformations.getOverviewData,
  },
  //jobsExplore
  {
    overviewZone: "gabon",
    listIndicators: [
      {
        image: economic_value,
        title: "Economic Value",
        indicator: "Total economic value added",
        tooltipDesc:
          "Cumulative economic value added across all sectors since 2018",
        type: "total",
      },
      {
        image: jobs,
        title: "Jobs",
        indicator: "Total direct and indirect jobs created",
        tooltipDesc: "Total direct and indirect jobs created",
        type: "count",
      },
      {
        image: value_addition,
        title: "Value Addition",
        indicator: "Revenue from wood transformation (non-logs) since 2018",
        tooltipDesc: "Revenue from wood transformation (non-logs) since 2018",
        type: "total",
      },
      {
        image: sector_diversification,
        title: "Sector Diversification",
        indicator: "% of non-wood exports from the SEZ (2021)",
        tooltipDesc: "% of non-wood exports from the SEZ (2021)",
        type: "percent",
      },
      {
        image: fiscal_impact,
        title: "Fiscal Impact",
        indicator: "Annual fiscal profit/loss on fiscal revenues  (2021)",
        tooltipDesc: "Annual fiscal profit/loss on fiscal revenues  (2021)",
        type: "total",
      },
      {
        image: sustainability,
        title: "Sustainability",
        indicator:
          "% increase in USD revenue per 1,000 m3 of wood supplied (2021)",
        tooltipDesc:
          "% increase in USD revenue per 1,000 m3 of wood supplied (2021)",
        type: "percent",
      },
    ],
  },
  //Benin
  {
    overviewZone: "togo",
    listIndicators: [
      {
        image: togoEconomic,
        title: "Economic Value",
        indicator: "Total economic value added",
        tooltipDesc:
          "Cumulative economic value added across all sectors since 2016",
        type: "total",
      },
      {
        image: togoJobs,
        title: "Jobs",
        indicator: "Total direct and indirect jobs created",
        tooltipDesc: "Total direct and indirect jobs created",
        type: "count",
      },
      {
        image: togoValue_addition,
        title: "Value Addition",
        indicator: "Export revenues from textiles and apparel since 2022",
        tooltipDesc: "Export revenues from textiles and apparel since 2022",
        type: "total",
      },
      {
        image: sector_diversification,
        title: "Sector Diversification",
        indicator: "% of non-agri and textiles exports",
        tooltipDesc: "% of non-agri and textiles exports",
        type: "percent",
      },
      {
        image: togoFiscalImpact,
        title: "Fiscal Impact",
        indicator: "Annual fiscal profit/loss on fiscal revenues",
        tooltipDesc: "Annual fiscal profit/loss on fiscal revenues",
        type: "total",
      },
      {
        image: togoSustainability,
        title: "Sustainability",
        indicator: "% increase in USD revenue per MT of Agri Product",
        tooltipDesc: "% increase in USD revenue per MT of Agri Product",
        type: "percent",
      },
    ],
  },
  //Benin
  {
    overviewZone: "benin",
    listIndicators: [
      {
        image: beninEconomic,
        title: "Economic Value",
        indicator: "Total economic value added",
        tooltipDesc: "Total economic value added",
        type: "total",
      },
      {
        image: jobs,
        title: "Jobs",
        indicator: "Total direct and indirect jobs created",
        tooltipDesc: "Total direct and indirect jobs created",
        type: "count",
      },
      {
        image: value_addition,
        title: "Value Addition",
        indicator: "Revenue from textiles and apparel since 2022",
        tooltipDesc: "Revenue from textiles and apparel since 2022",
        type: "total",
      },
      {
        image: beninSector,
        title: "Sector Diversification",
        indicator: "% of non-agri and textiles exports",
        tooltipDesc: "% of non-agri and textiles exports",
        type: "percent",
      },
      {
        image: fiscal_impact,
        title: "Fiscal Impact",
        indicator: "Annual fiscal profit/loss on fiscal revenues",
        tooltipDesc: "Annual fiscal profit/loss on fiscal revenues",
        type: "total",
      },
      {
        image: sustainability,
        title: "Sustainability",
        indicator: "% increase in USD revenue per MT of Agri Product",
        tooltipDesc: "% increase in USD revenue per MT of Agri Product",
        type: "percent",
      },
    ],
  },
  {
    key: "jobs-tab",
    table: "jobs",
    tabs: "jobs_value",
    variables: { table: "jobs" },
    preProcessor2: transformations.jobsExplore, //update
    preProcessor: transformations.economicOverview, //update
  },

  //ValueAddition
  {
    key: "value_addition",
    table: "value_addition",
    tabs: "revenue_value",
    preProcessor2: transformations.jobsExplore, //update
    preProcessor: transformations.economicOverview, //update
    //transform: transformations.onlyUnique,
    //query: store.queries.exploreDataQuery
    variables: { table: "value_addition" },
  },
  //Fiscal Impact
  {
    key: "fiscal_impact",
    table: "fiscal_impact",
    tabs: "fiscal_impact",
    preProcessor2: transformations.jobsExplore, //update
    preProcessor: transformations.economicOverview, //update
    //query: store.queries.exploreDataQuery
    variables: { table: "fiscal_impact" },
  },
  // Sector diversification
  {
    key: "sector_diversification",
    table: "sector_diversification",
    tabs: "sector",
    preProcessor2: transformations.sectorsExplore, //update
    preProcessor: transformations.economicOverview, //update
    preProcessor3: transformations.sustainabilityExploreGauge,
    //query: store.queries.exploreDataQuery
  },
  //Sustainability
  {
    key: "sustainability_circularity",
    preProcessor: transformations.economicOverview, //update
    preProcessor2: transformations.economicExplore, //update
    preProcessor3: transformations.sustainabilityExploreGauge,
    table: "sustainability_circularity",
    tabs: "sustainability",
    //query: store.queries.exporeSustainability
  },
  {
    key: "sustainability_diversity_and_inclusion",
    preProcessor: transformations.economicOverview, //update
    preProcessor2: transformations.jobsExplore, //update
    preProcessor3: transformations.sustainabilityExploreGauge,
    table: "sustainability_diversity_and_inclusion",
    tabs: "sustainability",
    //query: store.queries.exporeSustainability
  },

  {
    key: "sustainability_diversity_and_inclusion",
    preProcessor: transformations.economicOverview, // update
    preProcessor2: transformations.jobsExplore, // update
    preProcessor3: transformations.sustainabilityExploreGauge,
    table: "sustainability_diversity_and_inclusion",
    tabs: "sustainability",
    //query: store.queries.exporeSustainability
  },

  {
    key: "sustainability_skills_development",
    preProcessor: transformations.economicOverview, // update
    preProcessor2: transformations.economicExplore, // update
    preProcessor3: transformations.sustainabilityExploreGauge,
    table: "sustainability_skills_development",
    tabs: "sustainability",
    //query: store.queries.exporeSustainability
  },

  {
    key: "sustainability_supply_chain_management",
    preProcessor: transformations.economicOverview, // update
    preProcessor2: transformations.economicExplore, // update
    preProcessor3: transformations.sustainabilityExploreGauge,
    table: "sustainability_supply_chain_management",
    tabs: "sustainability",
    //query: store.queries.exporeSustainability
  },

  {
    key: "economic-overview",
    preProcessor: transformations.economicOverview,
    preProcessor2: transformations.economicExplore,
    page: "economic-value",
    variables: { table: "economic_value" },
  },

  {
    key: "currency-format",

    preProcessor: transformations.nFormatter,
    formatLookup: [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "Q" },
      { value: 1e18, symbol: "E" },
    ],
  },

  // Text descriptions
  {
    //gabon: {
    zone: "gabon",
    economic: {
      title1: "Our impact ",
      title2: "on the Gabonese Economy",
      description:
        "Covering 1 126 ha, GSEZ is an integrated multi-sector economic zone,with primary focus in timber sector. The SEZ offers a comprehensive set of services to hosted companies. GSEZ directly and indirectly results in over $400 million in exports annually, and has created over 15,000 sustainable jobs through businesses located in the SEZ and their up and downstream supply chains",
    },
    jobs: {
      title1: "Creating valuable ",
      title2: "jobs in Gabon",
      description:
        "GSEZ understands the critical need to develop sustainable, high-quality jobs in Gabon The zone is home to over 5,000 permanent direct jobs and has created over 10,000 sustainable indirect jobs through businesses located in the SEZ and their up and downstream supply chains.",
    },
    values_addition: {
      title1: "Adding value ",
      title2: "to key commodities",
      description:
        "Arise has pioineered wood transformation in Gabon. GSEZ has enabled Gabon to develop and modernise a wood sector that was previously not very promising by relying on specialisation, one-stop services and alignment with the national development strategy. With 3.4 million m3 produced each year, Gabon has become Africa’s leading producer and exporter of tropical plywood, and the world’s second largest exporter.The country intends to go further in adding value to its wood products by transforming GSEZ into a centre for the manufacture of “Made in Gabon” furniture.",
    },
    sector_diversificstion: {
      title1: "Sector diversification ",
      title2: "in the zone",
      description:
        "Beyond the core Wood industry, the SEZ is becoming a critical enabler of Gabon’s economic diversification and now houses a variety of additional industries including",
      descriptionlist: [
        "Concrete products",
        "Metal recycling",
        "Beverages",
        "Pharmaceuticals",
      ],
    },
    fiscal_impact: {
      title1: "Tax ",
      title2: "generated",
      description:
        "While operators within the Zone enjoy tax benefits, millions of dollars in in social security, payroll and corporate income taxes are generated directly and indirectly .",
    },
    sustainability: {
      title1: "Our sustainability ",
      title2: "pillars",
      description:
        "Faced with growing demands, GSEZ has made sustainability, traceability and certification one of its priorities. All of Gabon’s forest concessions are operated according to the sustainable forest management practices prescribed by the Gabonese Forest Code. In terms of traceability, since October 2018, GSEZ has benefited from the services of the Tracer-Nkok agency, which ensures that all logs entering the special economic zone are traceable. In the near future, the Government of Gabon will ensure that all the country’s forest concessions will be certified by the Forest Stewardship Council (FSC) and PAFC / PEFC Gabon in order to improve the traceability of the wood and respect for communities and workers.",
    },
    //},
  },

  // Togo
  {
    //gabon: {
    //Our Impact on the Togolese Economy
    zone: "togo",
    economic: {
      title1: "Our impact ",
      title2: "the Togolese Economy",
      description:
        "Plateform Industrial Adetikope (PIA) is an integrated industrial zone covering 400 Hectare. PIA is vertically integrated and focused at creating thriving value chains for storage, processing, manufacturing, and export along the Lomé – Burkina industrial corridor.",
    },
    jobs: {
      title1: "Creating valuable ",
      title2: "jobs in Togo",
      description:
        "PIA understands the the critical need to develop sustainable high, quality jobs in Togo. Firms operational in the zone already, as well as those who have started the process of operationalisation have already committed to create over 4,800 direct jobs with an estimated 15,000 additional jobs through upstream and downstream activities.",
    },
    values_addition: {
      title1: "From Fibre ",
      title2: "to Fashion",
      description:
        "PIA is set to transform agricultural and manufacturing value chains in Togo. The Textile Park (TP) developed by Arise IIP, in partnership with the Togolese Government as part of the Plateforme Industrielle Adetikopé (PIA), is set to transform.",
    },
    sector_diversificstion: {
      title1: "Sector diversification ",
      title2: "in the zone",
      description:
        "Beyond the core agricultural sectors (cotton, soybeans and cashew), the SEZ will be a critical enabler of Togo's economic diversification. Additional industries already committed in the park include:",
      descriptionlist: [
        "Pharmaceuticals manufacturing",
        "Biodisel production",
        "Metals processing",
      ],
    },
    fiscal_impact: {
      title1: "Tax ",
      title2: "generated",
      description:
        "While operators within the Zone enjoy tax benefits, millions of dollars in in social security, payroll and corporate income taxes are generated directly and indirectly .",
    },
    sustainability: {
      title1: "Our sustainability ",
      title2: "pillars",
      description:
        "The PIA Textile Park’s commitment to upholding sustainability ideals across the textile supply-chain is a reflection of Togo’s greater vision and will be a model for future industrial ventures in the country. Our aim for the Textile Park is for it to eventually become a benchmark sustainability leader in the West African textile industry.  The PIA Textile Park has the scale, reach, and technical expertise required to alleviate adverse environmental and social impacts within the textile industry; especially the interconnected regional and local supply chains.PIA will rely on AtSource software to ensure our supply chains are traceable and transparent demonstrating how at each stage best practice measures are implemented. AtSource enables us to facilitate communication between agents operating at different stages of the supply chain, mapping our cotton’s life-cycle.",
    },
    //},
  },

  // Benin

  {
    //gabon: {
    //Our Impact on the Togolese Economy
    zone: "benin",
    economic: {
      title1: "Our impact ",
      title2: "on Benin's Economy",
      description:
        "The Glo-Djigbé Industrial Zone (GDIZ) is located over 1,640 hectares just 45 km from Cotonou and is dedicated to the local transformation of agricultural product including cotton, cashews, pineapples, shea nuts and soybeans, among others. GDIZ is expected to attract an investment of at least $1.4 billion in the first phase to create about 12,000 jobs.",
    },
    jobs: {
      title1: "Creating valuable ",
      title2: "jobs in Benin",
      description:
        "GDIZ understands the the critical need to develop sustainable high, quality jobs in Benin. Firms operational in the zone already, as well as those who have started the process of operationalisation have already committed to create over 10,000  jobs.",
    },
    values_addition: {
      title1: "From Fibre ",
      title2: "to Fashion",
      description:
        "Benin can rely on a well-established cotton sector which accounts for 12% of GDP and represents 60% of the country’s industrial activity. Benin is already equipped with sizeable production facilities for first level transformation, an approximate 325,000 people strong cost-efficient workforce, and more than 700,000 tons of cotton seed production.",
    },
    sector_diversificstion: {
      title1: "Sector diversification ",
      title2: "in the zone",
      description:
        "Beyond the core agricultural sectors (cotton, soybeans and cashew), the SEZ will be a critical enabler of Benin's economic diversification. Additional industries already committed in the park include: ",
      descriptionlist: [
        "Pharmaceuticals manufacturing",
        "Electric bike manufacture",
        "Laptop and phone production",
      ],
    },
    fiscal_impact: {
      title1: "Tax ",
      title2: "generated",
      description:
        "While operators within the Zone enjoy tax benefits, millions of dollars in in social security, payroll and corporate income taxes are generated directly and indirectly .",
    },
    sustainability: {
      title1: "Our sustainability ",
      title2: "pillars",
      description:
        "The GDIZ's Textile Park will uphold ARISE IIP's  sustainability ideals across the textile supply-chain GDIZ will rely on AtSource software to ensure our supply chains are traceable and transparent demonstrating how at each stage best practice measures are implemented. AtSource enables us to facilitate communication between agents operating at different stages of the supply chain, mapping our cotton’s life-cycle.",
    },
    //},
  },
];
export default indiactorMeta;
