import React from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
const Login = () => {
  return (
    <div className="bg-white container mx-auto px-2 h-full">
      <div className="">
        {/*CREATE   lg:w-1/2 px-4 */}
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white border-0">
          <div className="rounded-t mb-0 px-8 py-8">
            <div className="flex items-center">
              <h2>
                <span className="text-light-green font-body-text">ADMIN </span>

                <span className="font-body-text">LOGIN</span>
              </h2>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div className="relative w-full mb-6">
                <label
                  className="normal-casee text-gray-700 text-base	font-bold mb-2 font-body-text"
                  htmlFor="grid-password"
                >
                  Username
                </label>
                <input
                  type="text"
                  className="border-2 px-3 py-3 placeholder-gray-400 text-gray-700 bg-gray-100 rounded text-sm focus:outline-none focus:ring w-full h-18"
                  placeholder="Username"
                  //style={{ transition: "all .15s ease" }}
                />
              </div>

              <div className="relative w-full mb-6">
                <label
                  className="normal-case text-gray-700 text-base font-bold mb-2 font-body-text"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password"
                  className="border-2 px-3 py-3 placeholder-gray-400 text-gray-700 bg-gray-100 rounded text-sm focus:outline-none focus:ring w-full h-18"
                  placeholder="Password"
                  //style={{ transition: "all .15s ease" }}
                />
              </div>
              {/*LOGIN BUTTON*/}
              <div className="flex items-center">
                <IoChevronForwardOutline
                  className="text-light-green"
                  size={40}
                />
                <button
                  onClick={() => alert("Login")}
                  className="text-xl font-title font-bold no-underline text-white"
                >
                  <span className="text-light-green font-body-text text-xl">
                    LOGIN
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
