import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import OverviewCard from "../elements/OverviewCards"
import LifeCyle from "../model/LifeCycle"
import store from "../Store"
import GlobeLoading from "../elements/GlobeLoading"
import Dropdown from "../elements/Dropdown"
import CheckBoxDropdown from "../elements/CheckboxDropdown"
import Typography from "@mui/material/Typography"
import ToggleButtons from "../elements/ToggleButtons"
import { filterQuery } from "../model/Query"
import StackedBarchart from "../components/StackedBarchart"
import DialogPopup from "../components/DialogPopup"

const dropdownProps = {
  variant: "h6",
  style: {
    fontFamily: '"Open Sans"',
    color: "black",
    lineHeight: 1.4,
    fontSize: "1.5rem",
  },
  gutterBottom: true,
}

const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
  (el) => el.key === "currency-format"
)[0]

const Beneficaition = ({ zones }) => {
  const valueAdditionData = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "value_addition"
  )[0]
  valueAdditionData.variables.zones = zones

  const zoneInfo = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.zone === zones
  )
  //console.log(zoneInfo[0].jobs)
  const sectorsDefaults = {
    gabon: "Wood and Paper",
    benin: "Textiles and Apparel",
    togo: "Textiles and Apparel",
  }

  const defaultYearList = zones == 'gabon'? ["2018", "2019", "2020", "2021"]:["2022"] 

  const [currentTab, setCurrentTab] = useState("1")
  const buttonsValues = ["Total ", "Direct", "Indirect"]
  const [active, setActive] = useState(buttonsValues[1])
  const [sector, setSector] = useState(sectorsDefaults[zones])
  const [years, setYears] = useState(defaultYearList)
  const [yearsList, setYearsList] = useState([])
  let [sectorList, setSectorList] = useState([])

  const Yearsvariables = {
    variables: { table: "value_addition", filter: "year", schema: zones },
  }
  const Sectorvariables = {
    variables: { table: "value_addition", filter: "Sector", schema: zones },
  }
  //const Indicatorvariables = {
  //  variables: { table: "value_addition", filter: "Indicator" },
  //}
  const tabs = [
    {
      id: 1,
      tabTitle: "Overview",
      content: overviewData(),
    },
    {
      id: 2,
      tabTitle: "Explore Data",
      content: exploreData(),
    },
  ]
  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  function handleToggleChange(event) {
    setActive(event.target.value)
  }

  // console.log("Printing the sector list");
  // console.log(sectorList);
  // sectorList = sectorList.filter((value) => value !== "All Sectors");
  // console.log(sectorList);

  function exploreData() {
    return (
      <div>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          alignItems={"flex-end"}
        >
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Sector</p>
            <Dropdown
              setIndicator={setSector}
              indicatorList={sectorList.filter(
                (value) => value !== "All Sectors"
              )}
              indicator={sector}
              id="sector"
            />
          </Grid>
          {/*<Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">
              Indicator
            </p>
            <Dropdown
              setIndicator={setIndicator}
              indicatorList={selected_all}
              indicator={indicator}
              id="indicator"
            />
          </Grid>*/}
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Year</p>
            <CheckBoxDropdown
              setIndicator={setYears}
              indicatorList={yearsList}
              indicator={years}
              id="year"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ToggleButtons
              setIndicator={handleToggleChange}
              indicator={active}
              totalOff={true}
              indirectOff={true}
            />
          </Grid>
        </Grid>
        <div className="pt-4 h-1/2">
          <LifeCyle
            query={store.queries.exploreDataQuery}
            variables={{
              table: valueAdditionData.table,
              sector: sector,
              years: years,
              sub_sector: active,
              tab: valueAdditionData.tabs,
              schema: zones,
            }}
            processor={valueAdditionData.preProcessor2}
            loader={<GlobeLoading />}
          >
            {(data) => (
              <StackedBarchart data_={data.data} labels={data.labels} otherOptions={{
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                  datalabels: {
                    display: "auto",
                    color: "white",
                    formatter: function (value) {
                      return value > 0
                        ? `$${currencyFormat.preProcessor(value, currencyFormat.formatLookup)}`
                        : `$${-currencyFormat.preProcessor(
                            Math.abs(value),
                            currencyFormat.formatLookup
                          )}`
                    },
                  },
                  title: {
                    display: false,
                  },
                },
              }}/>
            )}
          </LifeCyle>
          <DialogPopup zones={zones} />
        </div>
      </div>
    )
  }
  useEffect(() => {
    const getDropdownLists = async () => {
      const years_ = await filterQuery(Yearsvariables)
      const sectors_ = await filterQuery(Sectorvariables)
      //const indicators_ = await filterQuery(Indicatorvariables)
      setYearsList(years_)
      setSectorList(sectors_)
      //setIndicatorList(indicators_)
    }
    getDropdownLists()
  }, [])

  function overviewData() {
    return (
      <div>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid xs={6} md={8}>
            <div className="pl-10">
              <Typography
                sx={{
                  fontSize: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <h1 className="text-oswald-font pt-4">
                  <span className="text-light-green">
                    {zoneInfo[0].values_addition.title1}
                  </span>
                  <span className="">{zoneInfo[0].values_addition.title2}</span>
                </h1>
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: 20,
                    lg: 15,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <p className="pt-4 font-['Open_Sans']">
                  {zoneInfo[0].values_addition.description}
                </p>
              </Typography>
            </div>
            {/*</Grow>*/}
          </Grid>
          <Grid xs={6} md={4}>
            <div className="pl-12 pt-4">
              <LifeCyle
                query={store.queries.dataQuery}
                variables={{ table: "value_addition", schema: zones }}
                processor={valueAdditionData.preProcessor}
                loader={<GlobeLoading />}
              >
                {(data) =>
                  data.map((indicator) => (
                    <OverviewCard
                      value={indicator.sum}
                      description={indicator.Indicator}
                      type={indicator.type}
                    />
                  ))
                }
              </LifeCyle>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div className="w-full">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            style={{ fontWeight: currentTab === `${tab.id}` && "bolder" }}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p>{tab.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Beneficaition
