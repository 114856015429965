import React, { createContext, useState } from "react";
import tab from "../utility/ZoneNavTabEnum";

export const ZonePageContext = createContext();

export const ZonePageProvider = (props) => {
  const [view, setView] = useState(tab.Overview);

  return (
    <ZonePageContext.Provider value={{ tabs: [view, setView] }}>
      {props.children}
    </ZonePageContext.Provider>
  );
};
