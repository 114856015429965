import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const MethodologyCard = ({ number, image, buttonText, text }) => {
  const bodyProps = {
    style: { fontFamily: '"Open Sans"' },
  };
  return (
    <>
      <Box style={{ height: "100%" }}>
        <Card
          square={true}
          sx={{
            display: "flex",
            borderTop: "5px solid #2f2f7D",
            borderBottom: "5px solid #2f2f7D",
            boxShadow: "none",
          }}
          style={{ height: "100%", width: "90%" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0px auto",
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                flex: "1 0 auto",
                backgroundColor: "#E8ECEF",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                verticalAlign: "middle",
                overflowY: 'auto',
              }}
            >
              {/* Values */}
              <Box
                sx={{
                  textAlign: "center",
                  margin: "auto",
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    fontWeight: "900",
                    color: "#4682B4",
                    marginBottom: "5px",
                    fontFamily: '"Open Sans"',
                    fontSize: {
                      xl: 80,
                      lg: 60,
                      md: 60,
                      sm: 60,
                      xs: 60},
                  }}
                >
                  {number}
                </Typography>
                <img
                  className="pb-10 px-6 object-scale-down"
                  src={image}
                  alt="icon"
                  width="100px"
                  height="20px"
                />
              </Box>
              <Button
                sx={{ textTransform: "none", borderRadius: 0 }}
                style={{
                  backgroundColor: "#4682B4",
                  color: "white",
                  justifyContent: "center",
                  //padding: "10px 20px",
                  cursor: "pointer",
                  width: "100%",
                  height: "40px",
                  fontWeight: "bold",
                  display: "flex",
                  fontFamily: '"Open Sans"',
                  marginBottom: "10px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                {buttonText}
              </Button>
              <Typography
                align="left"
                style={{ wordWrap: "break-word", paddingTop: "10px", fontWeight: "bold",
                  fontSize: {
                    xl: 18,
                    lg: 12,
                    md: 12,
                    sm: 10,
                    xs: 10
              },}}
                {...bodyProps}
                variant="body"
                component="div"
              >
                {text}
              </Typography>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
};
export default MethodologyCard;
