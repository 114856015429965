import React from "react"
import ImpactOverviewCard from "../elements/ImpactOverviewCard"
import LifeCyle from "../model/LifeCycle"
import store from "../Store"
import Grid from "@mui/material/Grid"
import AudioLoading from "../elements/AudioLoading"
import { ThemeProvider } from "@mui/system";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { createTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/material"

const ImpactOverview = ({ zones }) => {
  const overviewData = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "overview-tab"
  )[0]

  console.log(`Zone: ${zones}`)
   const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },

    palette: {
      primary: {
        main: "#000000",
      },

      secondary: {
        main: "#9ca3af",
      },
    },
  });

   const zoneModel = {
    'gabon':'gabon_model.xlsx',
    'togo':'togo_model.xlsx',
    'benin':'benin_model.xlsx'
   }


  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{ width: "100%", height: "100%" }}
      >
        {/** */}
        <LifeCyle
          query={store.queries.dataQuery}
          variables={{table: "impact_overview", schema:zones}}
          processor={overviewData.preProcessor}
          processorArgs={{schema:zones}}
          loader={<AudioLoading />}
        >
          {(data) =>
            data.map((indicator) => (
              <Grid item xs={2} sm={4} md={4} key={indicator.description}>
                <ImpactOverviewCard
                  image={indicator.image}
                  title={indicator.title}
                  value={indicator.sum}
                  description={indicator.indicator}
                  tooltipDesc={indicator.tooltipDesc}
                  type={indicator.type}
                />
              </Grid>
            ))
          }
        </LifeCyle>


      </Grid>
      <br></br>
      <br></br>
        <Box
          m={1}
          display="flex"
          justifyContent="center"
          alignItems="justify-end"
        >

          <ThemeProvider theme={theme}>
            <Button
                variant="text"
                startIcon={<CloudDownloadIcon />}
                className="font-['Open_Sans'] text-sm"
                href={zoneModel[zones]}
                download={zoneModel[zones]}
                disableTouchRipple
              >
                  Download the Model
              </Button>
          </ThemeProvider>
         
        </Box>  
    </>
  )
}
export default ImpactOverview
