import React from "react"
import NavigationBar from "../components/NavigationBar"
import { PagesProvider } from "../states/PagesContext"
import MainView from "./MainViews"

const LandingPage = () => {
  return (
    <PagesProvider>
      <div className="bg-white h-full w-full">
        <NavigationBar />
        <MainView />
      </div>
    </PagesProvider>
  )
}

export default LandingPage
