import React, { useContext, useEffect, useState } from "react"
import { PagesContext } from "../states/PagesContext"
import Dashboard from "./Dashboard"
import CoverPage from "./CoverPage"
import AboutPage from "./AboutPage"
import LoginPage from "./LoginPage"
import ContactUs from "./ContactUs"
import PrivacyPolicy from "./PrivacyPolicy"
import Main from "./SideNavLandingPage"
import { ZonePageProvider } from "../states/ZonesPageContext"

const MainView = () => {
  const { mainViewState } = useContext(PagesContext)
  const [view] = mainViewState
  const [selectedZone, setSelectedZone] = useState("gabon")

  useEffect(() => {
    if (view === "GSEZ impact") {
      setSelectedZone("gabon")
    } else if (view === "GDIZ impact") {
      setSelectedZone("benin")
    } else if (view === "PIA impact") {
      setSelectedZone("togo")
    }
  }, [view])

  const ZoneView = () => {
    return (
      <ZonePageProvider>
        <Main zones={selectedZone} />
      </ZonePageProvider>
    )
  }

  switch (view) {
    case "Dashboard":
      return <Dashboard />
    case "Methodology":
      return <AboutPage />
    case "admin":
      return <LoginPage />
    case "GSEZ impact":
      return <ZoneView />
    case "PIA impact":
      return <ZoneView />
    case "GDIZ impact":
      return <ZoneView />
    case "Contact us":
      return <ContactUs />
    case "Privacy policy":
      return <PrivacyPolicy />
    default:
      return <CoverPage />
  }
}
export default MainView
