import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import LandingPage from "./views/LandingPage";
import "@fontsource/open-sans";

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Open Sans"',
    ].join(','),
  },});

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="h-full w-full">
      <QueryClientProvider client={queryClient}>
        <LandingPage />
      </QueryClientProvider>
    </div>
    </ThemeProvider>
  );
}
export default App;
