import React from "react"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Checkbox from "@mui/material/Checkbox"
import ListItemText from "@mui/material/ListItemText"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const CheckBoxDropdown = ({ setIndicator, indicatorList, indicator, id }) => {
  return (
    <div className="object-contain overscroll-none">
      <FormControl size="small" fullWidth>
        <Select
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#808080",
              border: 1,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ACA3C",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ACA3C",
            },
            fontFamily: '"Open Sans"',
          }}
          labelId="demo-multiple-checkbox-label"
          //style={{ height: 60}}
          id={id}
          multiple
          value={indicator}
          onChange={(val) => {
            setIndicator(val.target.value)
          }}
          //input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {indicatorList.map((val) => (
            <MenuItem key={val} value={val}>
              <Checkbox
                checked={indicator.indexOf(val) > -1}
                className="text-xs"
              />
              <ListItemText primary={val} sx={{ fontFamily: '"Open Sans"' }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
export default CheckBoxDropdown
