import * as queries from "./Query";
class Store {
  constructor(indicatorConfig) {
    //this.config = config;
    this.queries = queries;
    //this.transform = transform;
    this.sharedStates = {};
    this.indicatorMeta = indicatorConfig;
    //this.indicators = {};
  }
}
export default Store;
