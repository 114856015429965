import React, { useContext } from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import PageEndIcon from "@rsuite/icons/PageEnd"
import { Tooltip, tooltipClasses } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useSpring } from "react-spring"
import store from "../Store"
import { ZonePageContext } from "../states/ZonesPageContext"

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#93d5f5",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

const bodyProps = {
  style: { fontFamily: '"Open Sans"' },
}

const ImpactOverviewCard = ({
  image,
  title,
  value,
  description,
  tooltipDesc,
  type,
}) => {
  //console.log(value, "====== overview value")
  const { tabs } = useContext(ZonePageContext)
  const [view, setView] = tabs

  const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "currency-format"
  )[0]

  const finalValue = (value) => {
    if (type === "total") {
      return `$${currencyFormat.preProcessor(
        value,
        currencyFormat.formatLookup
      )}`
    } else if (type === "count") {
      return `${currencyFormat.preProcessor(
        value.toFixed(0),
        currencyFormat.formatLookup
      )}`
    } else {
      return `${(value * 100).toFixed(1)}%`
    }
  }

  const number = useSpring({
    val: value,
    from: { val: 0 },
    delay: 500,
    config: { duration: 3000 },
  })

  //let title
  //let image

  //if (tooltipDesc.includes("Cumulative economic value")) {
  //  title = "Economic Value"
  //  image = economic_value
  //} else if (tooltipDesc.includes("revenues across stages")) {
  //  title = "Value Addition"
  //  image = value_addition
  //} else if (tooltipDesc.includes("% of non-wood exports")) {
  //  title = "Sector Diversification"
  //  image = sector_diversification
  //  description = description.split("% of ")[1]
  //} else if (tooltipDesc.includes("fiscal")) {
  //  title = "Fiscal Impact"
  //  image = fiscal_impact
  //} else if (tooltipDesc.includes("revenue per 1,000 m3 of wood")) {
  //  title = "Sustainability"
   // image = sustainability
  //} else {
  //  title = "Jobs"
  //  image = jobs
  //}

  return (
    <>
      <Box style={{ height: "90%" }}>
        <Card
          square={true}
          sx={{
            display: "flex",
            borderTop: "5px solid #9aca3c",
            boxShadow: "none",
          }}
          style={{ height: "100%" }}
        >
          <CardMedia
            component="img"
            sx={{ width: 0.4, borderBottom: "5px solid #9aca3c" }}
            image={image}
            alt={title}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "0px auto",
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                flex: "1 0 auto",
                backgroundColor: "#E8ECEF",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                verticalAlign: "middle",
              }}
            >
              <Paper
                elevation={0}
                style={{
                  color: "#2f2f7D",
                  backgroundColor: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: "auto",
                  borderBottomLeftRadius: "25px",
                  borderBottomRightRadius: "25px",
                  minHeight: "30px",
                  minWidth: "80%",
                  marginTop: "-15px",
                  marginBottom: "25px",
                }}
              >
                <Typography
                  {...bodyProps}
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "2f2f7D",
                  }}
                  variant="body2"
                >
                  {title}
                </Typography>
              </Paper>
              {/* Values */}
              <Box
                sx={{
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                <Typography
                  component="div"
                  variant="h4"
                  sx={{
                    fontWeight: "900",
                    color: "#2f2f7D",
                    marginBottom: "5px",
                    fontFamily: '"Open Sans"',
                  }}
                >
                  {finalValue(value)}
                </Typography>
                <Typography {...bodyProps} variant="body2" component="div">
                  {sentenceCase(description)}
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </Card>
        <LightTooltip title={tooltipDesc} placement="top">
          <Button
            sx={{ textTransform: "none", borderRadius: 0 }}
            style={{
              backgroundColor: "#2f2f7D",
              color: "white",
              justifyContent: "center",
              padding: "10px 20px",
              cursor: "pointer",
              width: "45%",
              height: "40px",
              fontWeight: "bold",
              display: "flex",
              bottom: "20px",
              right: "calc((45%  * 60%) / 2)",
              float: "right",
              fontFamily: '"Open Sans"',
            }}
            onClick={() => setView(title)}
          >
            <PageEndIcon size="4em" style={{ marginRight: "2px" }} /> Learn more
          </Button>
        </LightTooltip>
      </Box>
    </>
  )
}

function sentenceCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default ImpactOverviewCard
