import React, { useState, createContext } from "react";

export const PagesContext = createContext();
export const PagesProvider = (props) => {
  const [mainView, setMainView] = useState("Landing");

  return (
    <PagesContext.Provider value={{ mainViewState: [mainView, setMainView] }}>
      {props.children}
    </PagesContext.Provider>
  );
};
