import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import OverviewCard from "../elements/OverviewCards"
import LifeCyle from "../model/LifeCycle"
import store from "../Store"
import GlobeLoading from "../elements/GlobeLoading"
import Dropdown from "../elements/Dropdown"
import CheckBoxDropdown from "../elements/CheckboxDropdown"
import Typography from "@mui/material/Typography"
import ToggleButtons from "../elements/ToggleButtons"
import { filterQuery } from "../model/Query"
import StackedBarchart, {
  stackPercentageOptions,
} from "../components/StackedBarchart"
import DialogPopup from "../components/DialogPopup"

const dropdownProps = {
  variant: "h6",
  style: {
    fontFamily: '"Open Sans"',
    color: "black",
    lineHeight: 1.4,
    fontSize: "1.5rem",
  },
  gutterBottom: true,
}

const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
  (el) => el.key === "currency-format"
)[0]

const Diversification = ({ zones }) => {
  const sectorDiversificationData = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "sector_diversification"
  )[0]

  const zoneInfo = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.zone === zones
  )

  const defaultYearList = zones == 'gabon'? ["2018", "2019", "2020", "2021"]:["2022"] 

  const [currentTab, setCurrentTab] = useState("1")
  const buttonsValues = ["Total ", "Direct", "Indirect"]
  const [active, setActive] = useState(buttonsValues[0])
  const [indicator, setIndicator] = useState("Revenues generated")
  const [years, setYears] = useState(defaultYearList)
  const [yearsList, setYearsList] = useState([])
  const [sectorList, setSectorList] = useState([])

  const selected_all = ["Revenues generated", "Export value percentage"]

  const [indicatorList, setIndicatorList] = useState([])

  const Yearsvariables = {
    variables: {
      table: "sector_diversification",
      filter: "year",
      schema: zones,
    },
  }
  const Sectorvariables = {
    variables: {
      table: "sector_diversification",
      filter: "Sector",
      schema: zones,
    },
  }
  const Indicatorvariables = {
    variables: {
      table: "sector_diversification",
      filter: "Indicator",
      schema: zones,
    },
  }
  const tabs = [
    {
      id: 1,
      tabTitle: "Overview",
      content: overviewData(),
    },
    {
      id: 2,
      tabTitle: "Explore Data",
      content: exploreData(),
    },
  ]

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  function handleToggleChange(event) {
    setActive(event.target.value)
  }

  function Center({ children }) {
    return (
      <div
        style={{
          display: "flex-col",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    )
  }

  function exploreData() {
    return (
      <div>
        <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          alignItems={"flex-end"}
        >
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-body-text text-xl font-semibold">Indicator</p>
            <Dropdown
              setIndicator={setIndicator}
              indicatorList={selected_all}
              indicator={indicator}
              id="indicator"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Year</p>
            <CheckBoxDropdown
              setIndicator={setYears}
              indicatorList={yearsList}
              indicator={years}
              id="year"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ToggleButtons
              setIndicator={handleToggleChange}
              indicator={active}
              directOff={true}
              indirectOff={true}
            />
          </Grid>
        </Grid>

        <div className="pt-4 h-1/2">
          <LifeCyle
            query={store.queries.exploreSectors}
            variables={{
              table: sectorDiversificationData.table,
              indicator: indicator,
              years: years,
              sub_sector: active,
              tab: sectorDiversificationData.tabs,
              schema: zones,
            }}
            processor={sectorDiversificationData.preProcessor2}
            loader={<GlobeLoading />}
          >
            {(data) => (
              <StackedBarchart
                data_={data.data}
                labels={data.labels}
                otherOptions={
                  indicator.includes("percentage") ? stackPercentageOptions : {
                    plugins: {
                      legend: {
                        display: true,
                        position: "bottom",
                      },
                      datalabels: {
                        display: "auto",
                        color: "white",
                        formatter: function (value) {
                          return value > 0
                            ? `$${currencyFormat.preProcessor(value, currencyFormat.formatLookup)}`
                            : `$${-currencyFormat.preProcessor(
                                Math.abs(value),
                                currencyFormat.formatLookup
                              )}`
                        },
                      },
                      title: {
                        display: false,
                      },
                    },
                  }
                }
              />
            )}
          </LifeCyle>
          
          <DialogPopup zones={zones} />
        </div>
      </div>
    )
  }

  useEffect(() => {
    const getDropdownLists = async () => {
      const years_ = await filterQuery(Yearsvariables)
      const sectors_ = await filterQuery(Sectorvariables)
      const indicators_ = await filterQuery(Indicatorvariables)
      setYearsList(years_)
      setSectorList(sectors_)
      setIndicatorList(indicators_)
    }
    getDropdownLists()
  }, [])

  function overviewData() {
    return (
      <div>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid xs={6} md={8}>
            <div className="pl-10">
              <Typography
                sx={{
                  fontSize: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <h1 className="text-oswald-font pt-4">
                  <span className="text-light-green">
                    {zoneInfo[0].sector_diversificstion.title1}
                  </span>
                  <span className="">
                    {zoneInfo[0].sector_diversificstion.title2}
                  </span>
                </h1>
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: 20,
                    lg: 15,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <p className="pt-4 font-body-text">
                  {zoneInfo[0].sector_diversificstion.description}
                  <ul className="list-disc">
                    {zoneInfo[0].sector_diversificstion.descriptionlist.map(
                      (val) => (
                        <li>{val}</li>
                      )
                    )}
                  </ul>
                </p>
              </Typography>
            </div>
            {/*</Grow>*/}
          </Grid>
          <Grid xs={6} md={4}>
            <div className="pl-12 pt-4">
              <LifeCyle
                query={store.queries.dataQuery}
                variables={{ table: "sector_diversification", schema: zones }}
                processor={sectorDiversificationData.preProcessor}
                loader={<GlobeLoading />}
              >
                {(data) =>
                  data.map((indicator) => (
                    <OverviewCard
                      value={indicator.sum}
                      description={indicator.Indicator}
                      type={indicator.type}
                    />
                  ))
                }
              </LifeCyle>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            style={{ fontWeight: currentTab === `${tab.id}` && "bolder" }}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p>{tab.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Diversification
