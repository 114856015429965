import React from "react";
import { Audio } from "react-loader-spinner";

const AudioLoading = () => {
  return (
    <div className="flex h-screen h-full w-full">
      <div className="h-full w-full">
        <div className="flex items-center justify-center h-3/4 w-full">
          <Audio color="#9ACA3C" />
        </div>
      </div>
    </div>
  );
};
export default AudioLoading;
