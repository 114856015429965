import React, { useState, useContext } from "react"
import mainlogo from "../resources/img/mainlogo.png"
import { IconButton } from "rsuite"
import { Search } from "@rsuite/icons"
import AdminIcon from "@rsuite/icons/Admin"
import "rsuite/dist/rsuite.min.css"
import { PagesContext } from "../states/PagesContext"

const NavigationBar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { mainViewState } = useContext(PagesContext)
  const [mainView, setMainView] = mainViewState
  const [clickvalue, setOnclick] = useState(false)

  const navLinks = pages.map((page) => (
    <a
      key={page}
      onClick={() => setMainView(page)}
      className="no-underline text-gray-800 font-body-text hover:text-gray-600 pb-10 focus:border-b-4 border-light-green"
      style={{
        textDecoration: "none",
        cursor:"pointer",
        fontWeight: mainView === page && "bolder",
        borderBottom: mainView === page && "4px solid #9ACA3C",
      }}
    >
      {page}
    </a>
  ))

  /*====Icon names and values ====*/
  const navIcons = [
    { name: "search", value: <Search /> },
    { name: "admin", value: <AdminIcon /> },
  ]
  /*====On Login button onclick ====*/
  const onClickHandler = (viewName) => {
    setMainView(viewName)
    setOnclick(true)
  }

  const navLinks3 = navIcons.map((icon) => (
    <IconButton
      icon={icon.value}
      size="large"
      onClick={() => {
        onClickHandler(icon.name)
      }}
      style={{ color: clickvalue === true ? "#9aca3c" : "#808080" }}
    />
  ))

  // const pages2 = ["Contact us", "Privacy policy"]
  const pages2 = ["Contact us"]
  const navLinks2 = pages2.map((page) => (
    <a
      key={page}
      onClick={() => setMainView(page)}
      className="no-underline text-sm text-gray-800 font-body-text hover:text-gray-600 pb-10 focus:border-b-4 border-light-green p-2"
      style={{
        textDecoration: "none",
        cursor:"pointer",
        fontWeight: mainView === page && "bolder",
        borderBottom: mainView === page && "4px solid #9ACA3C",
      }}
    >
      {page}
    </a>
  ))

  // Nav bar
  const Navbar = ({ menuOpen, setMenuOpen }) => (
    <div className="flex justify-between p-2 shadow-sm h-24 font-body-text">
      <div className="">
        <a href="/">
          <img src={mainlogo} alt="logo" className="h-20 pb-2 object-contain" />
        </a>
      </div>
      <div className="font-body-text">
        <nav className="hidden md:block space-x-12 font-body-text py-4 bottom-nav">
          {navLinks}
        </nav>
      </div>
      <div className="hidden md:block space-x-6 py-4 font-body-text">
        {navLinks2}
      </div>
      <button
        type="button"
        aria-label="Toggle mobile menu"
        onClick={() => setMenuOpen(!menuOpen)}
        className="rounded md:hidden focus:outline-none focus:ring focus:ring-gray-500 focus:ring-opacity-50 font-body-text"
      >
        <MenuAlt4Svg menuOpen={menuOpen} />
      </button>
    </div>
  )

  return (
    <div className="bg-white sticky top-0 z-50">
      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {menuOpen && <MobileMenu>{[navLinks, navLinks2]}</MobileMenu>}
    </div>
  )
}
const pages = ["Methodology", "GSEZ impact", "PIA impact", "GDIZ impact"]

const MobileMenu = ({ children }) => (
  <nav className="p-4 flex flex-col space-y-3 md:hidden">{children}</nav>
)

const MenuAlt4Svg = ({ menuOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`transition duration-100 ease h-8 w-8 ${
      menuOpen ? "transform rotate-90" : ""
    }`}
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M3 7a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 13a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
      clipRule="evenodd"
    />
  </svg>
)
export default NavigationBar
