import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import OverviewCard from "../elements/OverviewCards"
import LifeCyle from "../model/LifeCycle"
import store from "../Store"
import GlobeLoading from "../elements/GlobeLoading"
import Dropdown from "../elements/Dropdown"
import CheckBoxDropdown from "../elements/CheckboxDropdown"
import Typography from "@mui/material/Typography"
import ToggleButtons from "../elements/ToggleButtons"
import { filterQuery } from "../model/Query"
import StackedBarchart, {
  stackPercentageOptions,
} from "../components/StackedBarchart"
import _ from "lodash"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Button from "@mui/material/Button"
import StackedBarchartNum from "../components/StackedBarchartNum"
import DialogPopup from "../components/DialogPopup"


const dropdownProps = {
  variant: "h6",
  style: {
    fontFamily: '"Open Sans"',
    color: "black",
    lineHeight: 1.4,
    fontSize: "1.5rem",
  },
  gutterBottom: true,
}

const Jobs = ({ zones }) => {
  const jobsData = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.key === "jobs-tab"
  )[0]
  jobsData.variables.zones = zones

  const zoneInfo = store.indicatorMeta.indicatorConfig.filter(
    (el) => el.zone === zones
  )
  //console.log(zoneInfo[0].jobs)
  const defaultYearList = zones == 'gabon'? ["2018", "2019", "2020", "2021"]:["2022"] 

  const [currentTab, setCurrentTab] = useState("1")
  const buttonsValues = ["Total", "Direct", "Indirect"]
  const [active, setActive] = useState(buttonsValues[1])
  const [sector, setSector] = useState("All Sectors")
  const [indicator, setIndicator] = useState("Number of jobs")
  const [years, setYears] = useState(defaultYearList)
  const [yearsList, setYearsList] = useState([])
  let [sectorList, setSectorList] = useState([])
  const [indicatorList, setIndicatorList] = useState([])

  sectorList =
    indicator == "Wages percentage across sectors"
      ? ["All Sectors"]
      : sectorList
  console.log("Printing the sector list")
  console.log(sectorList)

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setActive(buttonsValues[1])
  }



  //console.log(`Active: ${active == "Direct"}`)
  //console.log(`Indicator: ${indicator} `)

  const queryFlag =
    indicator == "Number of jobs" ? true : active == "Direct" ? true : false

  console.log(`queryFlag: ${queryFlag}`)

  const Yearsvariables = {
    variables: { table: "Jobs", filter: "year", schema: zones },
  }
  const Sectorvariables = {
    variables: { table: "Jobs", filter: "Sector", schema: zones },
  }
  const Indicatorvariables = {
    variables: { table: "jobs", filter: "Indicator", schema: zones },
  }
  const tabs = [
    {
      id: 1,
      tabTitle: "Overview",
      content: overviewData(),
    },
    {
      id: 2,
      tabTitle: "Explore Data",
      content: exploreData(),
    },
  ]

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id)
  }

  function handleToggleChange(event) {
    setActive(event.target.value)
  }

  function Center({ children }) {
    return (
      <div
        style={{
          display: "flex-col",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    )
  }

  function exploreData() {
    return (
      <div>
        <Grid container columnSpacing={2} rowSpacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Sector</p>
            <Dropdown
              setIndicator={setSector}
              indicatorList={sectorList}
              indicator={sector}
              id="sector"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">
              Indicator
            </p>
            <Dropdown
              setIndicator={setIndicator}
              indicatorList={indicatorList}
              indicator={indicator}
              id="indicator"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <p className="font-['Open_Sans'] text-xl font-semibold">Year</p>
            <CheckBoxDropdown
              setIndicator={setYears}
              indicatorList={yearsList}
              indicator={years}
              id="year"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <ToggleButtons
              setIndicator={handleToggleChange}
              indicator={active}
            />
          </Grid>
        </Grid>
        <div className="pt-4 h-1/2">
          {queryFlag && (
            <LifeCyle
              query={store.queries.economicQuery}
              variables={{
                table: jobsData.table,
                sector: sector,
                indicator: indicator,
                years: years,
                sub_sector: active,
                tab: jobsData.tabs,
                schema: zones,
              }}
              processor={jobsData.preProcessor2}
              loader={<GlobeLoading />}
            >
              {(data) => (
                <>
                  {/* <div>{console.log(_.filter(data.data, obj => !_.every(obj.data,val => val === 0 )))}</div> */}
                  {indicator != "Wages percentage across sectors" && (
                    <StackedBarchartNum
                      data_={data.data}
                      labels={data.labels}
                      otherOptions={
                        indicator === "Wages percentage across sectors"
                          ? stackPercentageOptions
                          : {}
                      }
                    />
                  )}
                  {indicator == "Wages percentage across sectors" && (
                    <StackedBarchart
                      data_={_.filter(
                        data.data,
                        (obj) => !_.every(obj.data, (val) => val === 0)
                      )}
                      labels={data.labels}
                      otherOptions={
                        indicator === "Wages percentage across sectors"
                          ? stackPercentageOptions
                          : {}
                      }
                    />
                  )}
                </>
              )}
            </LifeCyle>
          )}
          {!queryFlag && (
            <Dialog
              open={true}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                {indicator == "Jobs by Type" && (
                  <DialogContentText id="alert-dialog-description">
                    Jobs by type are most accurate at a direct level based on
                    jobs by firms in the zone, further decoupling into
                    indirect or total would require significant assumptions
                  </DialogContentText>
                )}
                {indicator == "Wages percentage across sectors" && (
                  <DialogContentText id="alert-dialog-description">
                    Wages by sector are most accurate at a direct level based on
                    wages paid by firms in the zone, further decoupling into
                    indirect or total would require significant assumptions
                  </DialogContentText>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          )}

          <DialogPopup zones={zones}/>
        </div>
      </div>
    )
  }

  useEffect(() => {
    const getDropdownLists = async () => {
      const years_ = await filterQuery(Yearsvariables)
      const sectors_ = await filterQuery(Sectorvariables)
      const indicators_ = await filterQuery(Indicatorvariables)
      setIndicatorList(
        indicators_.filter(
          (item) =>
            item !== "Jobs created directly and indirectly in the market" && item !== "Jobs created during construction"
        )
      )
      setYearsList(years_)
      setSectorList(sectors_)
    }
    getDropdownLists()
  }, [])

  function overviewData() {
    return (
      <div>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid xs={6} md={8}>
            <div className="pl-10">
              <Typography
                sx={{
                  fontSize: {
                    xl: 30,
                    lg: 20,
                    md: 20,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <h1 className="text-oswald-font pt-4">
                  <span className="text-light-green">
                    {zoneInfo[0].jobs.title1}
                  </span>
                  <span className="">{zoneInfo[0].jobs.title2}</span>
                </h1>
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: 20,
                    lg: 15,
                    md: 15,
                    sm: 15,
                    xs: 10,
                  },
                }}
              >
                <p className="pt-4 font-['Open_Sans']">
                  {zoneInfo[0].jobs.description}
                </p>
              </Typography>
            </div>
            {/*</Grow>*/}
          </Grid>
          <Grid xs={6} md={4}>
            <div className="pl-12 pt-10">
              <LifeCyle
                query={store.queries.dataQuery}
                variables={{ table: "jobs", schema: zones }}
                processor={jobsData.preProcessor}
                loader={<GlobeLoading />}
              >
                {(data) =>
                  data.map((indicator) => (
                    <OverviewCard
                      value={indicator.sum}
                      description={indicator.Indicator}
                      type={indicator.type}
                    />
                  ))
                }
              </LifeCyle>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="tabs">
        {tabs.map((tab, i) => (
          <button
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
            style={{ fontWeight: currentTab === `${tab.id}` && "bolder" }}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      <div className="content">
        {tabs.map((tab, i) => (
          <div key={i}>
            {currentTab === `${tab.id}` && (
              <div>
                <p>{tab.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Jobs
