import React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"

const ContactCard = ({
    image,
    name,
    position,
    email,
    location
  }) => {
    return (
      <>
        <Box style={{ height: "100%" , width:"90%" }}>
          <Card
            square={true}
            sx={{
              display: "flex",
              //borderTop: "5px solid #9aca3c",
             // boxShadow: "none",
            }}
            style={{ height: "100%" }}
          >
            <CardMedia
              component="img"
              sx={{ width: 0.4, borderBottom: "5px solid #9aca3c" }}
              image={image}
              alt="title"
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "0px auto",
                width: "100%",
              }}
            >
              <CardContent
                sx={{
                  flex: "1 0 auto",
                  backgroundColor: "#E8ECEF",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  verticalAlign: "middle",
                }}
              >
                {/* Values */}
                <Box
                  sx={{
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                    <Typography
                    component="div"
                    variant="h8"
                    align="left"
                    sx={{
                      //fontWeight: "100",
                      //color: "#2f2f7D",
                      marginBottom: "5px",
                      fontFamily:'"Open Sans"',
                      fontSize: {
                        xl: 18,
                        lg: 14,
                        md: 12,
                        sm: 10,
                        xs: 10
                  },
                    }}
                  >
                <span className="font-extrabold text-arise-blue">Name: </span>
                    {name}
                  </Typography>
                  <Typography
                    component="div"
                    variant="h8"
                    align="left"
                    sx={{
                      //fontWeight: "100",
                      //color: "#2f2f7D",
                      marginBottom: "5px",
                      paddingTop: "10px",
                      fontFamily:'"Open Sans"',
                      fontSize: {
                        xl: 18,
                        lg: 14,
                        md: 12,
                        sm: 10,
                        xs: 10
                  },
                    }}
                    style={{ wordWrap: "break-word", paddingTop: "10px" }}
                  >
                    <span className="font-extrabold text-arise-blue">Position: </span>
                    {position}
                  </Typography>

                  <Typography
                    component="div"
                    variant="h8"
                    align="left"
                    sx={{
                      //fontWeight: "100",
                      //color: "#2f2f7D",
                      marginBottom: "5px",
                      paddingTop: "10px",
                      fontFamily:'"Open Sans"',
                      fontSize: {
                        xl: 18,
                        lg: 14,
                        md: 12,
                        sm: 10,
                        xs: 10
                  },
                    }}
                    style={{ wordWrap: "break-word", paddingTop: "10px" }}
                  >
                    <span className="font-extrabold text-arise-blue">Email: </span>
                   
                    {/* <div onClick={() => window.open(`mailto:${email}}`)}>{email}</div> */}

                    <a href={`mailto:${email}}`}> {email}</a>

                  </Typography>

                  <Typography
                    component="div"
                    variant="h8"
                    align="left"
                    sx={{
                      //fontWeight: "100",
                      //color: "#2f2f7D",
                      marginBottom: "10px",
                      paddingTop: "10px",
                      fontFamily:'"Open Sans"',
                      fontSize: {
                        xl: 18,
                        lg: 14,
                        md: 12,
                        sm: 12,
                        xs: 12
                  },
                      
                    }}
                    style={{ wordWrap: "break-word", paddingTop: "10px" }}
                  >
                    <span className="font-extrabold text-arise-blue">Location: </span>
                    {location}
                  </Typography>

                </Box>
              </CardContent>
            </Box>
          </Card>
        </Box>
      </>
    )
  }
  export default ContactCard