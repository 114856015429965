import React, { useContext} from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiListItem from "@mui/material/ListItem";
import { makeStyles, withStyles } from '@mui/styles';
import { ZonePageContext } from "../states/ZonesPageContext";


const useStyles = makeStyles((theme) => ({
  listItemText:{
    fontSize: {
      xl: 4,
      lg: 4,
      md: 2,
      sm: 2,
      xs: 2,
    },
    fontFamily: '"Open Sans"'
  }
}));


const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#9aca3c",
      color: "white",
      fontWeight: "bold",
      fontFamily: '"Open Sans"',
      "& .MuiListItemIcon-root": {
        color: "#cfd8dc"
      }
    },
    '&$disabled': {
      opacity: 0.5,
    },
  },
  image: {
    filter: "invert(96%) sepia(100%) saturate(12%) hue-rotate(237deg) brightness(103%) contrast(103%)"

},
  selected: {}
})(MuiListItem);

const SideNavButton = ({
  logo,
  title,
}) => {
const { tabs } = useContext(ZonePageContext);
const [view, setView] = tabs;

  const classes = useStyles();
  return (
    <div>
      <ListItem sx={{
          height: {
            xl: 60,
            lg: 40,
            md: 40,
            sm: 40,
            xs: 40
      },}}
      button
      selected={view === title }
      //onClick={(event) => handleListItemClick(event, title)}
      onClick={() => setView(title)}
      >
        <ListItemIcon>
        <img src= {logo} alt="" style={{ selected:classes.image}}/>
        </ListItemIcon>
         <ListItemText classes={{primary:classes.listItemText}} primary={title}/>
      </ListItem>
      </div>
    );
};

  export default SideNavButton;