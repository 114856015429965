import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

const LifeCyle = ({
  query,
  children,
  variables = {},
  processor = ({ data }) => data,
  processorArgs = {},
  loader = "LOADING...",
  noData = (
    <div style={{ margin: "0 auto" }}>
      <p style={{ fontSize: "40px" }} align="center">
        NO DATA
      </p>
    </div>
  ),
  debug = true,
  errorMessage = (e) => JSON.stringify(e),
}) => {
  const [data, setData] = useState(undefined);
  const [preprocessorLoading, setpreprocessorLoading] = useState(true);
  const { isLoading, isError, error, refetch } = useQuery(
    ["query-data", variables],
    () => query({ variables }),
    {
      refetchOnWindowFocus: false,
      //enabled: true,
      onSuccess: (res) => {
        try {
          setData(processor({ data: res, ...processorArgs }));
          setpreprocessorLoading(false);
        } catch (error) {
          setpreprocessorLoading(false);
          console.error(error);
          setData(undefined);
        }
        if (debug) {
          console.debug("--- Data fetched ---");
          console.debug(processor({ data: res, ...processorArgs }));

          //console.debug("---- Data processed ---");
          //console.debug(processor({ data: res, ...processorArgs }));
        }
      },
      onError: (err) => {
        console.error(" -- useQuery failed within the LifeCycle component --");
        console.error(err);
      },
    }
  );

  useEffect(() => {
    refetch(variables);
  }, Object.values(variables));

  if (error) return errorMessage(error);
  //if (isLoading) return loader;
  //if (!data || Object.keys(data).length < 1) return noData;
  if (preprocessorLoading) return loader;
  if (data === "undefined" && preprocessorLoading) return loader;
  if (!data && preprocessorLoading) return noData;


  return <>{data && children(data)}</>
}
export default LifeCyle


