import React from "react";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import MovingComponent from "react-moving-text";
import MethodologyCard from "../elements/MethodologyCard";
import collateIcon from "../resources/img/collate_icon.png";
import multipliersIcon from "../resources/img/multipliers_icon.png"
import analyze_icon from "../resources/img/analyze_icon.png"


const MethodologyPage = () => {
  const theme = createTheme({
    typography: {
      button: {
        textTransform: "none",
      },
    },

    palette: {
      primary: {
        main: "#000000",
      },

      secondary: {
        main: "#9ca3af",
      },
    },
  });

  return (
    <div className="flex flex-col items-center justify-center h-full w-full text-center bg-white">
      <div>
        <Grid container spacing={2}>
          <Grid xs={1} md={1}></Grid>
          <Grid xs={10} md={10}>
            <div className="flex flex-row justify-between">
              <h1 className="font-body-text text-xl sm:text-xl md:text-xl lg:text-4xl pt-4">
                <span className="text-oswald-font md:font-bold text-light-green">
                  Methodology
                </span>
                <span className="text-oswald-font text-[#2E286F]">
                  {" "}
                  for unpacking our impact
                </span>
                {/* <span className="">on the Gabonese Ecomony</span> */}
              </h1>

              <ThemeProvider theme={theme}>
                <Button
                  variant="text"
                  startIcon={<CloudDownloadIcon />}
                  className="font-['Open_Sans'] text-sm"
                  href="methodology.pdf"
                  download={"methodology.pdf"}
                  disableTouchRipple
                >
                   Download the Methodology              
                </Button>
            
              </ThemeProvider>
            </div>

            <p className="pt-4 font-['Open_Sans'] text-lg text-left">
              The platform uses{" "}
              <span className="text-light-green">
                {" "}
                aggregation and economic multipliers to unpack the impact of our
                economic zones on the host economies.
              </span>{" "}
              Modelling has ben conducted by an independent advisory firm, and
              outputs have been validated with both local and international
              economists. Read more about the methodology in the user-friendly
              download.
            </p>
          </Grid>
          <Grid xs={1} md={1}></Grid>
        </Grid>
        <br></br>
      </div>

      <Grid container spacing={2}>
        <Grid xs={1} md={1}></Grid>
        <Grid xs={10} md={10} alignItems="flex-start">
          <div className="grid grid-cols-3">
            <MovingComponent
              type="fadeInFromRight"
              duration="2000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
               {/*<div className="flex flex-col">*/}
              <MethodologyCard number={1} image={collateIcon} buttonText="Collate Data"
                text="Collect relevant data through primary and secondary research on key outputs as an indicator of 'demand'."
                />

              {/*</div>*/}

            </MovingComponent>

            <MovingComponent
              type="fadeInFromRight"
              duration="6000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              {/*<div className="flex flex-col">*/}
                {/* <h5 class="bg-green-400 text-white">Apply multipliers</h5> */}
                <MethodologyCard number={2} image={multipliersIcon} buttonText="Apply Multipliers"
                text="Use implied multipliers from I-O tables and other studies to assess the economic value created in different parts of the ecomomy from the outputs."/>
              {/*</div>*/}
            </MovingComponent>

            <MovingComponent
              type="fadeInFromRight"
              duration="10000ms"
              delay="0s"
              direction="normal"
              timing="ease"
              iteration="1"
              fillMode="none"
            >
              {/*<div className="flex flex-col">*/}
                {/* Assess and analyze outcomes */}

                {/* <h5 class="bg-blue-400 text-white">Assess and Analyze Outcomes</h5> */}
                <MethodologyCard number={3} image={analyze_icon} buttonText="Assess and Analyse Outcomes "
                text="Analyze and contrast the outcomes within the context of loacl realities, national objectives, and guidlines from other studies."/>
              {/*</div>*/}
            </MovingComponent>
          </div>
        </Grid>
        <Grid xs={1} md={1}></Grid>
      </Grid>
    </div>
  );
};

export default MethodologyPage;
