import React from "react"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"

const Dropdown = ({ setIndicator, indicatorList, indicator, id }) => {
  return (
    <div className="object-contain overscroll-none">
    <FormControl size="small" fullWidth>
        <Select
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#808080",
              border: 1,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ACA3C",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ACA3C",
            },
            fontFamily: '"Open Sans"',
          }}
          //xs={12} sm={6} md={4} lg={4} xl={3}
          labelId="indicator-dropdown"
          //style={{ height: 1/8}}
          id={id}
          value={indicator}
          onChange={(event) => {
            setIndicator(event.target.value)
          }}
          fullWidth
        >
          {indicatorList.map((val) => (
            <MenuItem
              value={val}
              className="text-xs"
              sx={{ fontFamily: '"Open Sans"' }}
            >
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
export default Dropdown
