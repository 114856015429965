import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Bar } from "react-chartjs-2"

import store from "../Store"

const currencyFormat = store.indicatorMeta.indicatorConfig.filter(
  (el) => el.key === "currency-format"
)[0]

const topLabels =  {
  id: "topLabels",
  afterDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      scales: { x, y },
    } = chart

    if (chart.data.datasets.length > 0) {
      chart.data.datasets[0].data.forEach((datapoint, index) => {
        const datasetArray = []

        chart.data.datasets.forEach((dataset) => {
          dataset.data[index] !== undefined &&
            datasetArray.push(dataset.data[index])
        })

        // total sum
        function totalSum(total, values) {
          return total + values
        }

        let sum = datasetArray.reduce(totalSum, 0)

        ctx.font = "bold 12px sans-serif"
        ctx.fillStyle = "black"
        ctx.textAlign = "center"
        if (datasetArray.length > 1 && sum > 0) {
          ctx.fillText(
            // sum.toLocaleString(),
            `$${currencyFormat.preProcessor(sum, currencyFormat.formatLookup)}`,
            x.getPixelForValue(index),
            chart.getDatasetMeta(datasetArray.length - 1).data[index].y - 10
          )
        }
      })
    }
  },
}

ChartJS.register(
  CategoryScale,
  ChartDataLabels,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
    datalabels: {
      display: "auto",
      color: "white",
      formatter: function (value) {
        return value > 0
          ? currencyFormat.preProcessor(value, currencyFormat.formatLookup)
          : -currencyFormat.preProcessor(
              Math.abs(value),
              currencyFormat.formatLookup
            )
      },
    },
    title: {
      display: false,
    },
  },
  animation: {
    duration: 2000,
  },
  scales: {
    x: {
      stacked: true,
      border: { display: false },
      grid: {
        display: false,
        borderWidth: 5,
        color: "rgb(32, 83, 214)",
      },
    },
    y: {
      stacked: true,
      border: { display: false },
      grace: "15%",
      grid: {
        display: true,
        tickLength: 0,
        borderWidth: 0,
        drawBorder: false,
        lineWidth: (context) => (context.tick.value === 0 ? 1 : 0), //Set only 0 line visible
      },
      ticks: {
        display: false,
      },
    },
  },
}

const StackedBarchart = ({ labels, data_, otherOptions }) => {
  const stackedColors = [
    "#2f2f7D",
    "#9ACA3C",
    "#00A94F",
    "#808285",
    "#003595",
    "#006CB7",
    "#369968",
  ]

  for (let i = 0; i < data_.length; i++) {
    data_[i]["backgroundColor"] = stackedColors[i]
  }

  const data = {
    labels,
    datasets: data_,
  }
  return (
    <div className="canvas-container" style={{ height: "80%" }}>
      <Bar
        options={{ ...options, ...otherOptions }}
        data={data}
        plugins={[topLabels]}
      />
    </div>
  )
}

export const stackPercentageOptions = {
  plugins: {
    datalabels: {
      color: "white",
      formatter: function (value) {
        const finalValue =
          value > 0
            ? currencyFormat.preProcessor(value.toFixed(1), currencyFormat.formatLookup)
            : -currencyFormat.preProcessor(
                Math.abs(value.toFixed(1)),
                currencyFormat.formatLookup
              )
        return `${Number(finalValue).toFixed(1)}%`
      },
    },
    topLabels: false,
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || ""
          if (label) {
            label += ": "
          }
          if (context.parsed.y !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "percent",
              maximumFractionDigits: 1,
              minimumFractionDigits: 1,
            }).format(context.parsed.y / 100)
          }
          return label
        },
      },
    },
    legend: {
      position: "bottom",
    },
  },
}

export default StackedBarchart
