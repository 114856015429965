import React from "react";
import { Audio } from "react-loader-spinner";

const GlobeLoading = () => {
  return (
    <div className="h-full w-full flex justify-center items-center rounded-full ">
      {/* <img
        className="animate-spin h-10 bg-transparent"
        alt="globe"
        src={globe}
      /> */}
      <Audio color="#9ACA3C" />
    </div>
  );
};
export default GlobeLoading;
