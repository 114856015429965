import React from "react"

const PrivacyPolicy = () => {
  return (
    <main>
      <div className="flex flex-col items-center justify-center h-full w-full text-center bg-white">
        <p>Privacy Policy</p>
      </div>
    </main>
  )
}

export default PrivacyPolicy
