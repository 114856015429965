import axios from "axios";

//const apiUrl = "http://127.0.0.1:8000";
const apiUrl = "https://ariseapi.herokuapp.com"
//const apiUrl = "https://arise-api-test.herokuapp.com";

export const economicQuery = async (variables) => {
  const res = await axios.get(
    `${apiUrl}/data?table=${
      variables.variables.table
    }&indicator=${encodeURIComponent(
      variables.variables.indicator
    )}&sector=${encodeURIComponent(
      variables.variables.sector
    )}&sub_sector=${encodeURIComponent(variables.variables.sub_sector)}&tab=${
      variables.variables.tab
    }&${variables.variables.years
      .map((n, index) => `years=${n}`)
      .join("&")}&schema_name=${variables.variables.schema}`
  );
  console.log(`${apiUrl}/data?table=${
      variables.variables.table
    }&indicator=${encodeURIComponent(
      variables.variables.indicator
    )}&sector=${encodeURIComponent(
      variables.variables.sector
    )}&sub_sector=${encodeURIComponent(variables.variables.sub_sector)}&tab=${
      variables.variables.tab
    }&${variables.variables.years
      .map((n, index) => `years=${n}`)
      .join("&")}&schema_name=${variables.variables.schema}`)
  return res.data;
};
export const exploreDataQuery = async (variables) => {
  const res = await axios.get(
    `${apiUrl}/data?table=${
      variables.variables.table
    }&sector=${encodeURIComponent(
      variables.variables.sector
    )}&sub_sector=${encodeURIComponent(variables.variables.sub_sector)}&tab=${
      variables.variables.tab
    }&${variables.variables.years
      .map((n, index) => `years=${n}`)
      .join("&")}&schema_name=${variables.variables.schema}`
  );
  return res.data;
};
export const exporeSustainability = async (variables) => {
  if (variables.variables.years !== undefined) {
    const res = await axios.get(
      `${apiUrl}/data?table=${
        variables.variables.table
      }&indicator=${encodeURIComponent(variables.variables.indicator)}&tab=${
        variables.variables.tab
      }&${variables.variables.years
        .map((n, index) => `years=${n}`)
        .join("&")}&schema_name=${variables.variables.schema}`
    );
    return res.data;
  } else {
    const res = await axios.get(
      `${apiUrl}/data?table=${
        variables.variables.table
      }&indicator=${encodeURIComponent(variables.variables.indicator)}&tab=${
        variables.variables.tab
      }&schema_name=${variables.variables.schema}`
    );
    return res.data;
  }
};

export const exploreSectors = async (variables) => {
  const res = await axios.get(
    `${apiUrl}/data?table=${
      variables.variables.table
    }&indicator=${encodeURIComponent(
      variables.variables.indicator
    )}&sub_sector=${encodeURIComponent(variables.variables.sub_sector)}&tab=${
      variables.variables.tab
    }&${variables.variables.years
      .map((n, index) => `years=${n}`)
      .join("&")}&schema_name=${variables.variables.schema}`
  );
  return res.data;
};

export const dataQuery = async (variables) => {
  //console.log(`${apiUrl}/data?table=${variables.variables.table}&schema_name=${variables.variables.schema}`, "=========1655")
  const res = await axios.get(
    `${apiUrl}/data?table=${variables.variables.table}&schema_name=${variables.variables.schema}`
  );
  return res.data;
};

export const filterQuery = async (variables) => {
  if (typeof variables.variables.filter !== "undefined") {
    const res = await axios.get(
      `${apiUrl}/filters?table=${variables.variables.table}&filteredvalue=${variables.variables.filter}&schema_name=${variables.variables.schema}`
    );
    return res.data;
  } else {
    const res = await axios.get(
      `${apiUrl}/filters?table=${variables.variables.table}&schema_name=${variables.variables.schema}`
    );
    return res.data;
  }
};
